import api, { s3request, ApiPromiseAction } from './../api';
import { AnyAction } from 'redux';
import { fulfilled, pending } from './helper';

const ADD_SNACKBAR_QUEUE = 'utility/addSnackbarQueue';
const OPEN_SNACKBAR = 'utility/openSnackbar';
const CLOSE_SNACKBAR = 'utility/closeSnackbar';
const OPEN_CONFIRM_DIALOG = 'utility/openConfirmDialog';
const CLOSE_CONFIRM_DIALOG = 'utility/closeConfirmDialog';
const READ_QUESTIONS = 'utility/readQuestions';
const READ_S3_DOCUMENT = 'utility/readS3Document';

export interface SnackbarProps {
  variant: 'error' | 'warning' | 'info' | 'success';
  message: string;
}

export interface QueueItem extends SnackbarProps {
  key: number;
}

export function addSnackbarQueue(data: QueueItem): AnyAction {
  return {
    type: ADD_SNACKBAR_QUEUE,
    payload: data,
  };
}

export function openSnackbar(data: SnackbarProps): AnyAction {
  return {
    type: OPEN_SNACKBAR,
    payload: data,
  };
}

export function closeSnackbar(): AnyAction {
  return {
    type: CLOSE_SNACKBAR,
  };
}

export function openConfirmDialog(onConfirm: () => void, confirmTitle: string): AnyAction {
  return {
    type: OPEN_CONFIRM_DIALOG,
    payload: {
      onConfirm,
      confirmTitle,
    },
  };
}

export function closeConfirmDialog(): AnyAction {
  return {
    type: CLOSE_CONFIRM_DIALOG,
  };
}

export function readQuestions(): ApiPromiseAction {
  const request = api.get('/questions/');
  return {
    type: READ_QUESTIONS,
    payload: request,
  };
}

export function readS3Document(key: string): ApiPromiseAction {
  const request = s3request.get(key);
  return {
    type: READ_S3_DOCUMENT,
    payload: request,
  };
}

export interface Question {
  id: number;
  category: string;
  title: string;
  body: string;
}

export interface UtilityState {
  snackbarQueueItem?: QueueItem;
  snackbarOpen: boolean;
  snackbarVariant: 'error' | 'warning' | 'info' | 'success';
  snackbarMessage: string;
  confirmDialogOpen: boolean;
  confirmTitle: string;
  onConfirm(): void;
  questions: Question[];
  document: string;
}

const INITIAL_UTILITY_STATE: UtilityState = {
  snackbarOpen: false,
  snackbarVariant: 'info',
  snackbarMessage: '',
  confirmDialogOpen: false,
  confirmTitle: '',
  onConfirm: () => {},
  questions: [],
  document: '',
};

export default function (
  state: UtilityState = INITIAL_UTILITY_STATE,
  action: AnyAction,
): UtilityState {
  switch (action.type) {
    case ADD_SNACKBAR_QUEUE: {
      return {
        ...state,
        snackbarQueueItem: action.payload,
      };
    }
    case OPEN_SNACKBAR: {
      return {
        ...state,
        snackbarOpen: true,
        snackbarVariant: action.payload.variant,
        snackbarMessage: action.payload.message,
        snackbarQueueItem: undefined,
      };
    }
    case CLOSE_SNACKBAR: {
      return {
        ...state,
        snackbarOpen: false,
        snackbarQueueItem: undefined,
      };
    }
    case OPEN_CONFIRM_DIALOG: {
      return {
        ...state,
        confirmDialogOpen: true,
        confirmTitle: action.payload.confirmTitle,
        onConfirm: action.payload.onConfirm,
      };
    }
    case CLOSE_CONFIRM_DIALOG: {
      return {
        ...state,
        confirmDialogOpen: false,
        confirmTitle: '',
        onConfirm: () => {},
      };
    }
    case fulfilled(READ_QUESTIONS): {
      return {
        ...state,
        questions: action.payload.data,
      };
    }
    case pending(READ_S3_DOCUMENT): {
      return {
        ...state,
        document: '',
      };
    }
    case fulfilled(READ_S3_DOCUMENT): {
      return {
        ...state,
        document: action.payload.data,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
