import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import TextField from '@material-ui/core/TextField';

interface TextFieldProps extends WrappedFieldProps {
  label: string;
}

const MuiTextField: React.FunctionComponent<TextFieldProps> = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    variant="outlined"
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export default MuiTextField;
