import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';

import Typography from '@material-ui/core/Typography';
import Progress from '../base/Progress';

import { readS3Document, UtilityState } from '../../state/utility';
import { AppState } from '../../state/rootReducer';

const Wrapper = styled.div`
  max-width: 700px;
  margin: 60px auto;
  padding: 0 16px;
  line-height: 1.5;
`;

interface TermsProps {
  readS3Document: typeof readS3Document;
}

class Terms extends React.Component<TermsProps & UtilityState> {

  componentWillMount() {
    this.props.readS3Document('docs/hygr-terms.md');
  }

  render() {
    if (!this.props.document) {
      return <Progress/>;
    }

    return (
      <Wrapper>
        <Helmet
          title="이용약관 | 향유고래"
        />
        <Typography variant="h4">
          이용약관
        </Typography>
        <ReactMarkdown source={this.props.document}/>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  document: state.utility.document,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readS3Document },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
