import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Progress from '../base/Progress';
import snacbar from '../../utils/snackbar';

import UserInfoForm, { UserInfoFormData } from './UserInfoForm';
import MembershipItem from '../membership/MembershipItem';

import { readMe, updateMe, UserState } from '../../state/user';
import { readMemberships, MembershipState } from '../../state/membership';
import { AppState } from '../../state/rootReducer';

const Wrapper = styled.div`
  margin: 60px auto;
  max-width: 400px;
  padding: 0 16px;
`;

const Spacer = styled.div`
  height: 32px;
`;

interface InfoProps {
  readMe: typeof readMe;
  updateMe: typeof updateMe;
  readMemberships: typeof readMemberships;
}

class Info extends React.Component<InfoProps & UserState & MembershipState & RouteComponentProps> {
  handleSubmit = (data: UserInfoFormData) => {
    this.props.updateMe(data);
  }

  componentDidMount() {
    this.props.readMe();
    this.props.readMemberships();
  }

  render() {
    if (!this.props.email || !this.props.nickname) {
      return <Progress/>;
    }

    return (
      <Wrapper>
        <Helmet
          title="내 정보 | 향유고래"
        />
        <Typography gutterBottom variant="h6">
          정보
        </Typography>
        <Card>
          <UserInfoForm
            initialValues={{
              email: this.props.email,
              nickname: this.props.nickname,
              phone_number: this.props.phone_number,
            }}
            onSubmit={this.handleSubmit}
            onSubmitSuccess={() => snacbar.success('정보를 수정했습니다')}
            enableReinitialize
          />
        </Card>
        <Spacer/>
        <Typography gutterBottom variant="h6">
          모임
        </Typography>
        {this.props.list.map(membership => (
          <MembershipItem key={membership.club} membership={membership}/>
        ))}
        {this.props.list.length === 0 &&
          <Typography variant="subtitle2">
            아직 신청한 모임이 없습니다
          </Typography>
        }
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  id: state.user.id,
  email: state.user.email,
  nickname: state.user.nickname,
  phone_number: state.user.phone_number,
  list: state.membership.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readMe, updateMe, readMemberships },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Info);
