import React, { useState, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill'; // Typescript
import { RangeStatic } from 'quill';
import ImageResize from 'quill-image-resize-module-react';
import Dropzone from 'react-dropzone';
import Compressor from 'compressorjs';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import 'react-quill/dist/quill.snow.css';
import { uploadImage } from '../../utils/uploader';

const ColorClass = Quill.import('attributors/class/color');
const SizeStyle = Quill.import('attributors/style/size');
Quill.register(ColorClass, true);
Quill.register(SizeStyle, true);
Quill.register('modules/imageResize', ImageResize);

interface PostEditorProps {
  onSubmit(body: string): void | any;
  onCancel?(): void;
  style?: React.CSSProperties;
  initialValue?: string;
}

// REF: https://medium.com/@engross/react-quill에서-image-upload와-ie-ios-대응하기-3a8a709ee4ae

const getModules = (handlers: object) => ({
  toolbar: {
    handlers,
    container: [
      ['bold', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ],
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    displayStyles: {
      backgroundColor: 'black',
      border: 'none',
      color: 'white',
    },
  },
});

const formats = [
  'bold', 'italic', 'underline',
  'align',
  'list', 'bullet',
  'link', 'image',
];

const PostEditor: React.FunctionComponent<PostEditorProps> = (props) => {
  const initialized = useRef(false);
  const modules = useRef({});
  const reactQuillRef = useRef<ReactQuill>();
  const dropzoneRef = useRef<Dropzone>();
  const [body, setBody] = useState(props.initialValue ? props.initialValue : '');

  const handleChange = (value: string) => {
    setBody(value);
  };

  const handleSubmit = () => {
    props.onSubmit(body);
    setBody('');
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    await acceptedFiles.map(async (file) => {
      new Compressor(file, {
        quality: 0.7,
        maxWidth: 2048,
        success: async (result) => {
          const url = await uploadImage(result as File);
          const reactQuill = reactQuillRef.current as ReactQuill;
          const quill = reactQuill.getEditor() as Quill;
          const range = quill.getSelection() as RangeStatic;
          quill.insertEmbed(range.index, 'image', url.split('?')[0]);
          range.index = range.index + 1;
          quill.setSelection(range);
          quill.focus();
        },
      });
    });
  };

  const imageHandler = () => {
    if (dropzoneRef.current) dropzoneRef.current.open();
  };

  if (!initialized.current) {
    modules.current = getModules({ image: imageHandler });
    initialized.current = true;
  }

  return (
    <Card style={props.style}>
      <div style={{ display: 'none' }}>
        <Dropzone
          ref={(el: Dropzone) => (dropzoneRef.current = el)}
          onDrop={handleDrop}
          accept="image/*"
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <CardContent>
        <ReactQuill
          ref={(el: any) => (reactQuillRef.current = el)}
          value={body}
          onChange={handleChange}
          modules={modules.current}
          formats={formats}
        />
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {props.initialValue ? '글 수정' : '글 쓰기'}
        </Button>
        {props.onCancel &&
          <Button
            color="primary"
            onClick={props.onCancel}
          >
            취소
          </Button>
        }
      </CardActions>
    </Card>
  );
};

export default PostEditor;
