import React, { useState, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

const ScrollToTop: React.FunctionComponent<RouteComponentProps> = (props) => {
  const [pathname, setPathname] = useState('');
  const prevPathnameRef = useRef(pathname);
  const prevPathname = prevPathnameRef.current;

  useEffect(
    () => {
      const currentPathname = props.location.pathname;
      if (currentPathname !== prevPathname) {
        window.scrollTo(0, 0);
      }
      setPathname(currentPathname);
    },
    [props.location.pathname, prevPathname]
  );

  return(
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export default withRouter(ScrollToTop);
