import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import * as colors from '../../assets/values/colors';

const SectionWhite = styled.div`
  padding: 20px;
`;

const SectionTitleBlue = styled.div`
  margin-top: 30px;
  font-size: 24px;
  font-weight: 900;
  color: ${colors.textBlue};
`;

const SectionBlue = styled.div`
  padding: 20px;
  background-color: #044882;
  background-image: linear-gradient(138deg, rgba(32, 154, 214, 0), #044882);
`;

const SectionTitleWhite = styled.div`
  margin-top: 30px;
  font-size: 24px;
  font-weight: 900;
  color: ${colors.textWhite};
`;

const SectionSubtitleWhite = styled.div`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 400;
  color: ${colors.textWhite};
`;

interface SectionProps {
  color: 'primary' | 'white';
  title: string;
  subtitle?: string;
}

const Section: React.FunctionComponent<SectionProps> = (props) => {
  if (props.color === 'primary') {
    return (
      <SectionBlue>
        <Grid container justify="center">
          <Grid item xs={12} lg={8}>
            <SectionTitleWhite>{props.title}</SectionTitleWhite>
            {props.subtitle && <SectionSubtitleWhite>{props.subtitle}</SectionSubtitleWhite>}
            {props.children}
          </Grid>
        </Grid>
      </SectionBlue>
    );
  }

  return (
    <SectionWhite>
      <Grid container justify="center">
        <Grid item xs={12} lg={8}>
          <SectionTitleBlue>{props.title}</SectionTitleBlue>
          {props.subtitle && <SectionSubtitleWhite>{props.subtitle}</SectionSubtitleWhite>}
          {props.children}
        </Grid>
      </Grid>
    </SectionWhite>
  );
};

export default Section;
