import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { openSnackbar, closeSnackbar, QueueItem, UtilityState } from '../../state/utility';
import { AppState } from '../../state/rootReducer';

interface ErrorSnackBarProps {
  openSnackbar: typeof openSnackbar;
  closeSnackbar: typeof closeSnackbar;
}

class AppSnackbar extends React.Component<ErrorSnackBarProps & UtilityState> {
  queue: QueueItem[] = [];

  componentDidUpdate() {
    if (!this.props.snackbarQueueItem) return;

    this.queue.push(this.props.snackbarQueueItem);

    if (this.props.snackbarOpen) {
      this.props.closeSnackbar();
    } else {
      this.processQueue();
    }
  }

  handleClose = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.closeSnackbar();
  }

  processQueue = () => {
    if (this.queue.length > 0) {
      const item = this.queue.shift() as QueueItem;
      this.props.openSnackbar({
        message: item.message,
        variant: item.variant,
      });
    }
  }

  handleExited = () => {
    this.processQueue();
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={this.props.snackbarOpen}
        autoHideDuration={6000}
        onClose={this.handleClose}
        onExited={this.handleExited}
        message={<span>{this.props.snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon/>
          </IconButton>,
        ]}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  snackbarQueueItem: state.utility.snackbarQueueItem,
  snackbarOpen: state.utility.snackbarOpen,
  snackbarMessage: state.utility.snackbarMessage,
  snackbarVariant: state.utility.snackbarVariant,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { closeSnackbar, openSnackbar },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AppSnackbar) as any;
