import React, { useEffect, ChangeEvent, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import format from 'date-fns/format';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ClubListItem from './ClubListItem';
import Progress from '../base/Progress';
import ComingSoon from '../utility/ComingSoon';

import { readClubs, setClubFilter, ClubState, ClubFilter } from '../../state/club';
import { AppState } from '../../state/rootReducer';

const Wrapper = styled.div`
  margin: 60px 16px;
`;

interface ClubListProps extends RouteComponentProps {}

interface ClubFilterTab {
  value: number;
  label: string;
  filterFields: string[];
}

export const clubFilterTabs: ClubFilterTab[] = [
  {
    value: 0,
    label: '모집중인 모임',
    filterFields: ['sale_finish__gt'],
  },
  {
    value: 1,
    label: '진행중인 모임',
    filterFields: ['sale_finish__lte', 'meeting_finish__gt'],
  },
  {
    value: 2,
    label: '지난 모임',
    filterFields: ['meeting_finish__lte'],
  },
];

export const getClubFilter = (value: number) => {
  const filterFields = clubFilterTabs[value].filterFields;
  const clubFilter: ClubFilter = {};
  filterFields.forEach((field: string) => {
    clubFilter[field] = format(new Date(), 'YYYY-MM-DD');
  });
  return clubFilter;
};

const ClubList: React.FunctionComponent<ClubListProps> = (props) => {
  const dispatch = useDispatch();
  const { list, loading, filterTab } = useSelector<AppState, ClubState>(state => state.club);

  const initializeClubList = useCallback(
    (tab: number) => {
      const clubFilter = getClubFilter(tab);
      dispatch(setClubFilter(clubFilter, tab));
      dispatch(readClubs(clubFilter));
    },
    [dispatch],
  );

  const handleTabChange = (event: ChangeEvent<{}>, value: number) => {
    initializeClubList(value);
  };

  useEffect(() => initializeClubList(filterTab), [initializeClubList, filterTab]);

  return (
    <Wrapper>
      <Grid container justify="center">
        <Helmet
          title="모임 둘러보기 | 향유고래"
        />
        <Grid item xs={12} md={8}>
        <Tabs
          value={filterTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          style={{ marginBottom: 30 }}
        >
          {clubFilterTabs.map(tab =>
            <Tab key={tab.value} label={tab.label}/>,
          )}
        </Tabs>
          {loading && <Progress/>}
          <Grid container spacing={3} justify="center">
          {!loading && list.length === 0 && <ComingSoon text="아직 모임이 없습니다"/>}
          {!loading && list.length > 0 && list.map(club => (
            <Grid item xs={12} sm={6} md={4} key={club.id}>
              <ClubListItem
                onClick={() => props.history.push(`/clubs/${club.id}`)}
                club={club}
              />
            </Grid>
          ))}
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ClubList;
