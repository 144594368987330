import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import SignUpForm from './SignUpForm';
import snackbar from '../../utils/snackbar';

import { signUp, signIn, AuthState } from '../../state/auth';
import { AppState } from '../../state/rootReducer';

const Wrapper = styled.div`
  margin: 60px auto;
  max-width: 400px;
  padding: 0 16px;
`;

interface SignUpProps {
  signUp: typeof signUp;
  signIn: typeof signIn;
}

interface SignUpState {
  data: object | undefined;
}

class SignUp extends React.Component<SignUpProps & AuthState & RouteComponentProps, SignUpState> {
  state: SignUpState = {
    data: undefined,
  };

  handleSubmit = (data: object) => {
    this.setState({ data });
    return this.props.signUp(data);
  }

  componentWillMount() {
    if (this.props.signedIn) {
      this.props.history.goBack();
    }
  }

  componentDidUpdate() {
    if (this.props.signedUp && this.state.data) {
      this.props.signIn(this.state.data);
      snackbar.success('회원가입을 성공했습니다');
      this.props.history.goBack();
    }

    if (this.props.signUpError !== null) {
      snackbar.error('회원가입을 실패했습니다');
    }
  }

  render() {
    return (
      <Wrapper>
        <Helmet
          title="회원가입 | 향유고래"
        />
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5">
              회원가입
            </Typography>
            <SignUpForm
              onSubmit={this.handleSubmit}
              initialValues={{ privacy: true, terms: true }}
            />
          </CardContent>
        </Card>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  signedIn: state.auth.signedIn,
  signedUp: state.auth.signedUp,
  signUpError: state.auth.signUpError,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { signUp, signIn },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
