import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Share from '../utility/Share';
import LinkButton from '../base/LinkButton';
import PaymentPrice from '../payment/PaymentPrice';

import { Club } from '../../state/club';

import * as colors from '../../assets/values/colors';
import * as strings from '../../assets/values/strings';
import { formatDate } from '../../utils/formatter';

const Title = styled.div`
  font-weight: 900;
  font-size: 2.125rem;
  color: ${colors.primaryBlue};
`;

const Subtitle = styled.div`
  font-weight: 700;
`;

const Spacer = styled.div`
  height: 16px;
`;

const ShortDescription = styled.div`
  line-height: 1.5;
`;

const ExtraActions = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  /* text-align: right; */
`;

export interface ClubInfoProps {
  detail: Club;
  showPrice?: boolean;
  showPayment?: boolean;
  buttonDisabled?: boolean;
  buttonLabel?: string;
  onButtonClick?(): void;
}

const ClubInfo: React.FunctionComponent<ClubInfoProps> = (props) => {

  return (
    <React.Fragment>
      <Title>{props.detail.name}</Title>
      <Typography gutterBottom variant="h6" color="primary">
        <Subtitle>{props.detail.subtitle}</Subtitle>
      </Typography>
      <Spacer/>
      <Typography variant="subtitle1" color="primary">
        <ShortDescription>
          <ReactMarkdown source={props.detail.short_description}/>
        </ShortDescription>
      </Typography>
      <Spacer/>
      <Typography variant="h6" color="primary">
      {props.detail.meeting_start === props.detail.meeting_finish ?
        formatDate(props.detail.meeting_start) :
        `${formatDate(props.detail.meeting_start)} ~ ${formatDate(props.detail.meeting_finish)}`
      }
      </Typography>
      {props.showPrice &&
      <>
        <Spacer/>
        <Typography variant="h6" color="primary">
          정원 {props.detail.maximum_capacity}명, 신청 {props.detail.member_count}명
        </Typography>
        <Spacer/>
        <PaymentPrice
          fullPrice={props.detail.full_price}
          discountedPrice={props.detail.discounted_price}
          maximumCapacity={props.detail.maximum_capacity}
          minimumCapacity={props.detail.minimum_capacity}
          memberCount={props.detail.member_count}
          totalMonth={props.detail.total_month || 3}
        />
      </>
      }
      {props.showPayment &&
      <>
        {/*
        {props.detail.minimum_capacity > props.detail.member_count &&
        <Typography gutterBottom variant="subtitle2" color="primary">
          신청 인원이 {props.detail.minimum_capacity}명에 도달하면 얼리버드 할인이 종료되고, 모임이 확정됩니다.
        </Typography>
        }
        */}
        <Spacer/>
        <ExtraActions>
          <Share
            url={document.location.href}
            text={`${props.detail.name}: ${props.detail.subtitle}`}
            variant="text"
            size="medium"
          />
          <LinkButton
            to={strings.kakaoChatUrl}
            external
            color="primary"
            variant="outlined"
            size="small"
          >
            {strings.contactWithKakaoTalk}
          </LinkButton>
        </ExtraActions>
        <Button
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={props.onButtonClick}
          disabled={props.buttonDisabled}
        >
          {props.buttonLabel}
        </Button>
      </>
      }
    </React.Fragment>
  );
};

export default ClubInfo;
