import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import * as Sentry from '@sentry/browser';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import * as strings from './assets/values/strings';

if (!String.prototype.includes) {
  // eslint-disable-next-line
  String.prototype.includes = function (search, start = 0) {
    if (start + search.length > this.length) {
      return false;
    }

    return this.indexOf(search, start) !== -1;
  };
}

if (!Array.prototype.includes) {
  // eslint-disable-next-line
  Object.defineProperty(Array.prototype, 'includes', {
    value(searchElement:any, fromIndex:number) {

      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      const n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x:number, y:number) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        // c. Increase k by 1.
        k = k + 1;
      }

      // 8. Return false
      return false;
    },
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://53539c10b929468bb8143fcfc3c04515@sentry.io/1411936',
  });
}
// should have been called before using it here
// ideally before even rendering your react app

IMP.init(strings.iamportMerchantId);

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
