import api, { ApiPromiseAction, ApiAction } from '../api';
import { fulfilled } from './helper';

import { UserInfoFormData } from '../components/user/UserInfoForm';

const READ_ME = 'user/readMe';
const UPDATE_ME = 'user/updateMe';
const REQUEST_PASSWORD_RESET_TOKEN = 'user/requestPasswordResetToken';
const RESET_PASSWORD = 'user/resetPassword';

export function readMe(): ApiPromiseAction {
  const request = api.get('/users/me/');
  return {
    type: READ_ME,
    payload: request,
  };
}

export function updateMe(data: UserInfoFormData): ApiPromiseAction {
  const request = api.patch('/users/me/', data);
  return {
    type: UPDATE_ME,
    payload: request,
  };
}

export function requestPasswordResetToken(data: object): ApiPromiseAction {
  const request = api.post('/password_reset/', data);
  return {
    type: REQUEST_PASSWORD_RESET_TOKEN,
    payload: request,
  };
}

export function resetPassword(data: object): ApiPromiseAction {
  const request = api.post('/password_reset/confirm/', data);
  return {
    type: RESET_PASSWORD,
    payload: request,
  };
}

export interface UserState {
  id: number | undefined;
  email: string | undefined;
  nickname: string | undefined;
  phone_number: string | undefined;
  passwordResetTokenRequested: boolean;
  resetPasswordSuccess: boolean;
}

const INITIAL_AUTH_STATE: UserState = {
  id: undefined,
  email: undefined,
  nickname: undefined,
  phone_number: undefined,
  passwordResetTokenRequested: false,
  resetPasswordSuccess: false,
};

export default function (state: UserState = INITIAL_AUTH_STATE, action: ApiAction): UserState {
  switch (action.type) {
    case fulfilled(READ_ME): {
      const { id, email, nickname, phone_number } = action.payload.data;
      return {
        ...state,
        id,
        email,
        nickname,
        phone_number,
      };
    }
    case fulfilled(UPDATE_ME): {
      const { id, email, nickname, phone_number } = action.payload.data;
      return {
        ...state,
        id,
        email,
        nickname,
        phone_number,
      };
    }
    case fulfilled(REQUEST_PASSWORD_RESET_TOKEN): {
      return {
        ...state,
        passwordResetTokenRequested: action.payload.ok,
      };
    }
    case fulfilled(RESET_PASSWORD): {
      return {
        ...state,
        resetPasswordSuccess: action.payload.ok,
      };
    }
    default: {
      return state;
    }
  }
}
