import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer, { AuthState } from './auth';
import boardReducer, { BoardState } from './board';
import clubReducer, { ClubState } from './club';
import membershipReducer, { MembershipState } from './membership';
import noticeReducer, { NoticeState } from './notice';
import paymentReducer, { PaymentState } from './payment';
import reviewReducer, { ReviewState } from './review';
import userReducer, { UserState } from './user';
import utilityReducer, { UtilityState } from './utility';

export interface AppState {
  auth: AuthState;
  board: BoardState;
  club: ClubState;
  membership: MembershipState;
  notice: NoticeState;
  payment: PaymentState;
  review: ReviewState;
  user: UserState;
  utility: UtilityState;
}

const rootReducer = combineReducers({
  auth: authReducer,
  board: boardReducer,
  club: clubReducer,
  membership: membershipReducer,
  notice: noticeReducer,
  payment: paymentReducer,
  review: reviewReducer,
  user: userReducer,
  utility: utilityReducer,
  form: formReducer,
});

export default rootReducer;
