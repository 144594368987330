import React, { useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import styled  from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PageNumberPaginator from '../utility/PageNumberPaginator';

import { readNotices, Notice, NoticeState } from '../../state/notice';
import { AppState } from '../../state/rootReducer';

import { formatDate } from '../../utils/formatter';

interface MagazineListProps extends NoticeState, RouteComponentProps {
  readNotices: typeof readNotices;
}

const Wrapper = styled.div`
  padding: 16px;
  margin: 60px auto;
  max-width: 700px;
`;

const MagazineList: React.FunctionComponent<MagazineListProps> = (props) => {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      props.readNotices();
      initializedRef.current = true;
    }
  });

  const handleNoticeClick = (notice: Notice) => {
    props.history.push(`/magazine/${notice.id}`);
  };

  const handleNoticesRead = (page: number) => {
    props.readNotices(page);
  };

  return (
    <Wrapper>
      <Helmet
        title="매거진 | 향유고래"
      />
      <Typography variant="h4" gutterBottom>
        매거진
      </Typography>
      {props.list.map(notice => (
        <Card
          key={notice.id}
          onClick={() => handleNoticeClick(notice)}
          style={{ cursor: 'pointer' }}
        >
          <CardActionArea>
            <Grid container>
              <Grid item xs={12} md={3}>
                <CardMedia
                  image={notice.cover}
                  title={notice.title}
                  style={{ height: 0, paddingTop: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <CardContent>
                  <Typography variant="h6">
                    {notice.title}
                  </Typography>
                  <Typography variant="body2">
                    {notice.body.substring(0, 100)}...
                  </Typography>
                  <Typography variant="caption">
                    {formatDate(notice.created_at)}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      ))}
      <div style={{ marginTop: 30 }}>
        <PageNumberPaginator
          currentPage={props.currentPage}
          count={props.count}
          onPageClick={handleNoticesRead}
        />
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  list: state.notice.list,
  currentPage: state.notice.currentPage,
  count: state.notice.count,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readNotices },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MagazineList);
