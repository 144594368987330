import React, { useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ClipboardCopy from 'clipboard-copy';

import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

interface ShareProps {
  url: string;
  text: string;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary';
  size?: 'small' | 'large' | 'medium';
}

const Share: React.FunctionComponent<ShareProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCopyURL = (event: React.MouseEvent) => {
    ClipboardCopy(document.location.href)
      .then(() => handleClose());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color={props.color ? props.color : 'primary'}
        variant={props.variant ? props.variant : 'outlined'}
        size={props.size ? props.size : 'small'}
      >
        <ShareIcon fontSize="small" style={{ marginRight: 4 }}/>공유하기
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} dense>
          <FacebookShareButton
            url={document.location.href}
            quote={props.text}
          >
            <Typography variant="body2">페이스북으로 공유하기</Typography>
          </FacebookShareButton>
        </MenuItem>
        <MenuItem onClick={handleClose} dense>
          <TwitterShareButton
            url={document.location.href}
            title={props.text}
          >
            <Typography variant="body2">트위터로 공유하기</Typography>
          </TwitterShareButton>
        </MenuItem>
        <MenuItem onClick={handleCopyURL} dense>
          <Typography variant="body2">URL 복사하기</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default Share;
