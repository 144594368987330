import React, { useEffect, ChangeEvent } from 'react';
import { match, RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import parse from 'date-fns/parse';
import compareAsc from 'date-fns/compare_asc';

import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';

import { getClubFilter } from './ClubList';
import ClubInfo from './ClubInfo';
import ClubListItem from './ClubListItem';
import StickyTabs from './StickyTabs';
import ClubDescription from './ClubDescription';
import ClubPostList from './ClubPostList';
import ClubPostDetail from './ClubPostDetail';
import Progress from '../base/Progress';
import BottomFixedChat from '../utility/BottomFixedChat';

import { AuthState } from '../../state/auth';
import { readClubs, readClubDetail, ClubState } from '../../state/club';
import { readMemberships, Membership } from '../../state/membership';
import { resetPaymentData } from '../../state/payment';
import { AppState } from '../../state/rootReducer';

import * as strings from '../../assets/values/strings';
import { Typography } from '@material-ui/core';

const Wrapper = styled.div`
  margin: 60px auto;
  padding: 0px 16px;
  max-width: 900px;
`;

const Section = styled.div`
  margin: 24px 0px;
`;

const Spacer = styled.div`
  height: 16px;
`;

interface ClubDetailProps {
  match: match<{clubId: string }>;
}

const clubDetailTabs = [
  {
    value: 0,
    label: '소개',
    path: '',
  },
  {
    value: 1,
    label: '커뮤니티',
    path: 'posts',
  },
];

const ClubDetail: React.FC<ClubDetailProps & RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { jwtPayload } = useSelector<AppState, AuthState>(state => state.auth);
  const { list, detail, loading } = useSelector<AppState, ClubState>(state => state.club);
  const memberships = useSelector<AppState, Membership[]>(state => state.membership.list);

  const isSoldOut = () => {
    if (!detail) return false;
    return detail.member_count === detail.maximum_capacity;
  };

  const isPurchased = () => {
    if (!memberships) return false;
    const membershipClubs = memberships.map(membership => membership.club);
    const clubId = parseInt(props.match.params.clubId, 10);
    return membershipClubs.includes(clubId);
  };

  const isSaleFinished = () => {
    if (!detail) return true;
    return compareAsc(parse(detail.sale_finish), new Date()) === -1;
  };

  const handlePurchaseClick = () => {
    dispatch(resetPaymentData());
    props.history.push(strings.routes.getPaymentDetail(props.match.params.clubId));
  };

  const handleTabChange = (event: ChangeEvent<{}>, value: number) => {
    const newPath = strings.routes.getClubDetail(
      props.match.params.clubId,
      clubDetailTabs[value].path,
    );
    props.history.push(newPath);
  };

  const getActiveTabValue = (url: string) => {
    for (let index = 1; index < clubDetailTabs.length; index += 1) {
      const element = clubDetailTabs[index];
      if (url.includes(element.path)) {
        return element.value;
      }
    }
    return 0;
  };

  const initializeClubDetail = () => {
    const clubId = parseInt(props.match.params.clubId, 10);
    dispatch(readClubDetail(clubId));
    dispatch(readClubs(getClubFilter(0)));
    if (jwtPayload) {
      dispatch(readMemberships());
    }
  };

  useEffect(initializeClubDetail, [parseInt(props.match.params.clubId, 10)]);

  if (!detail || loading) {
    return <Progress/>;
  }

  return (
    <Wrapper>
      <Helmet
        title={`${detail.name} | ${strings.appName}`}
      />
      <Section>
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} lg={7}>
            <CardMedia
              style={{
                height: 0,
                paddingTop: '100%',
              }}
              image={detail.image}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <ClubInfo
              showPayment={!isSaleFinished()}
              showPrice={!isSaleFinished()}
              detail={detail}
              onButtonClick={handlePurchaseClick}
              buttonDisabled={isSoldOut() || isPurchased()}
              buttonLabel={
                isPurchased() ? strings.membershipPurchased : strings.purchaseMembership
              }
            />
          </Grid>
        </Grid>
      </Section>
      <Divider/>
      <StickyTabs
        color="primary"
        variant="contained"
        size="large"
        fullWidth
        onClick={handlePurchaseClick}
        disabled={isSoldOut() || isPurchased() || isSaleFinished()}
        label={isPurchased() ? strings.membershipPurchased : strings.purchaseMembership}
        title={detail.name}
        onTabChange={handleTabChange}
        tabs={clubDetailTabs}
        tabValue={getActiveTabValue(document.location.href)}
      />
      <Switch>
        <Route
          path={strings.routes.clubDetailPostDetail}
          component={ClubPostDetail}
        />
        <Route
          path={strings.routes.clubDetailPostList}
          component={ClubPostList}
        />
        <Route
          path={strings.routes.clubDetail}
          render={() =>
            <ClubDescription
              name={detail && detail.name}
              subtitle={detail && detail.name}
              description={detail && detail.description}
            />
          }
        />
      </Switch>
      <Divider/>
      <Section>
        <Typography color="primary" variant="h5" style={{ fontWeight: 700 }}>
          {strings.lookAroundOtherClubs}
        </Typography>
        <Spacer/>
        <Grid container spacing={3} justify="center">
        {list.length > 0 && list.map(club =>
          (detail && club.id !== detail.id) && (
            <Grid item xs={12} md={4} key={club.id}>
              <ClubListItem
                onClick={() => props.history.push(strings.routes.getClubDetail(club.id))}
                club={club}
              />
            </Grid>
          ))}
        </Grid>
      </Section>
      <BottomFixedChat/>
    </Wrapper>
  );
};

export default ClubDetail;
