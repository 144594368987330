import { store } from '../App';
import { addSnackbarQueue } from '../state/utility';

const error = (message: string) => {
  store.dispatch(addSnackbarQueue({ message, variant: 'error', key: new Date().getTime() }));
};

const success = (message: string) => {
  store.dispatch(addSnackbarQueue({ message, variant: 'error', key: new Date().getTime() }));
};

export default { error, success };
