export const iamportMerchantId = 'imp77612209';
export const googleAnalyticsID = 'UA-134320636-1';
export const facebookPixelId = '2151455468267447';

export const kakaoFriendUrl = 'http://pf.kakao.com/_IgdNj';
export const kakaoChatUrl = 'http://pf.kakao.com/_IgdNj/chat';
export const instagramUrl = 'http://instagram.com/hyangyu_gorae/';
export const facebookUrl = 'http://facebook.com/hyangyugorae/';
export const brunchUrl = 'https://brunch.co.kr/@3000won';
export const officialEmail = 'hello@hyangyugorae.com';

export const GoogleAnalyticsEvent = {
  category: {
    commerce: 'Commerce',
  },
  action: {
    purchase: 'Purchase',
  },
  label: {
    membership: 'Membership',
  },
};

export const contactWithKakaoTalk = '카카오톡 채널로 문의하기';

export const routes = {
  root: '/',
  clubList: '/clubs',
  clubDetail: '/clubs/:clubId',
  getClubDetail: (id: number | string, tab: string = '') => `/clubs/${id}/${tab}`,
  clubDetailPostList: '/clubs/:clubId/posts',
  clubDetailPostDetail: '/clubs/:clubId/posts/:postId',
  paymentComplete: '/payment/complete',
  paymentDetail: '/payment/:clubId',
  getPaymentDetail: (id: number | string) => `/payment/${id}`,
  reviewList: '/reviews',
  user: '/user',
  signUp: '/sign_up',
  signIn: '/sign_in',
  forgotPassword: '/forgot_password',
  resetPassword: '/reset_password',
  noticeList: '/notices',
  noticeDetail: '/notices/:noticeId',
  magazineList: '/magazine',
  magazineDetail: '/magazine/:noticeId',
  faq: '/faq',
  terms: '/terms',
  privacy: '/privacy',
};

/*
  App
*/
export const appName = '향유고래';
export const appDescription = '향유고래는 모두의 삶에 예술적 영감이 스며들 수 있도록, 함께 문화예술을 향유하는 모임입니다.';

/*
  Club
*/
export const purchaseMembership = '모임 신청하기';
export const membershipPurchased = '모임 신청완료';
export const lookAroundOtherClubs = '다른 모임 둘러보기';
