import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import Button from '@material-ui/core/Button';
import TextField from '../form/TextField';

import * as validator from '../../utils/formValidator';

export interface ForgotPasswordFormData {
  email: string;
}

interface ForgotPasswordFormProps extends InjectedFormProps<ForgotPasswordFormData> {}

const ForgotPasswordForm: React.FunctionComponent<ForgotPasswordFormProps> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div>
        <Field
          name="email"
          component={TextField}
          label="이메일"
          margin="dense"
          validate={[validator.email]}
          fullWidth
        />
      </div>
      <div style={{ marginTop: 8 }}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={props.submitting}
        >
          이메일 보내기
        </Button>
      </div>
    </form>
  );
};

export default reduxForm<ForgotPasswordFormData>({
  form: 'forgotPasswordForm',
})(ForgotPasswordForm);
