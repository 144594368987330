import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';

import { signIn, signUp, AuthState } from '../../state/auth';
import { AppState } from '../../state/rootReducer';

interface SignMixinProps {
  signIn: typeof signIn;
  signUp: typeof signUp;
}

interface SignMixinState {
  showSignUp: boolean;
  data: object | undefined;
}

const SignUpHelper = styled.div`
  margin: 16px 0px;
`;

const Spacer = styled.div`
  height: 16px;
`;

class SignMixin extends React.Component<SignMixinProps & AuthState, SignMixinState> {
  state: SignMixinState = {
    showSignUp: false,
    data: undefined,
  };

  handleSignUp = (data: object) => {
    this.setState({ data });
    return this.props.signUp(data);
  }

  handleSignIn = (data: object) => {
    return this.props.signIn(data);
  }

  handleSwitch = () => {
    this.setState({ showSignUp: !this.state.showSignUp });
  }

  componentDidUpdate() {
    if (this.props.signedUp && this.state.data) {
      this.props.signIn(this.state.data);
    }
  }

  render() {
    if (this.state.showSignUp) {
      return (
        <div>
          <SignUpForm onSubmit={this.handleSignUp}/>
          <Spacer/>
          <Button
            variant="outlined"
            fullWidth
            onClick={this.handleSwitch}
          >
            돌아가기
          </Button>
        </div>
      );
    }
    return (
      <div>
        <SignInForm
          onSubmit={this.handleSignIn}
          submitting={this.props.submitting}
        />
        <SignUpHelper>
          <Typography variant="body2" align="center">
            아직 회원이 아니신가요?
          </Typography>
        </SignUpHelper>
        <Button
          variant="outlined"
          fullWidth
          onClick={this.handleSwitch}
        >
          가입하기
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  signedIn: state.auth.signedIn,
  signInError: state.auth.signInError,
  signedUp: state.auth.signedUp,
  signUpError: state.auth.signUpError,
  submitting: state.auth.submitting,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { signIn, signUp },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SignMixin) as any;
