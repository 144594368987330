import React, { useRef } from 'react';
import { match } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import queryString from 'query-string';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Editor from '../board/PostEditor';
import Progress from '../base/Progress';
import ClubPostCard from './ClubPostCard';

import { AuthState } from '../../state/auth';
import { Membership } from '../../state/membership';
import { readPosts, createPost, BoardState } from '../../state/board';
import { AppState } from '../../state/rootReducer';

interface ClubPostListProps extends AuthState, BoardState {
  memberships: Membership[];
  readPosts: typeof readPosts;
  createPost: typeof createPost;
  match: match<{ clubId: string }>;
}

const PostsWrapper = styled.div`
  margin: 60px auto;
  max-width: 700px;
`;

const CardWrapper = styled.div`
  margin-bottom: 32px;
`;

const getCursor = (next: string) => {
  const name = 'cursor';
  const parsed = queryString.parse(next);
  return parsed[name];
};

const ClubPostList: React.FunctionComponent<ClubPostListProps> = (props) => {

  const initialized = useRef(false);

  if (!initialized.current) {
    const clubId = parseInt(props.match.params.clubId, 10);
    props.readPosts(clubId);
    initialized.current = true;
  }

  const handlePostSubmit = (body: string) => {
    const club = parseInt(props.match.params.clubId, 10);
    const user = props.jwtPayload && props.jwtPayload.user_id;
    if (!user) return;

    const data = {
      body,
      user,
      club,
      title: '향유고래',
    };
    return props.createPost(data);
  };

  const handleReadMore = () => {
    if (!props.next) return;
    const cursor = getCursor(props.next);
    const clubId = parseInt(props.match.params.clubId, 10);
    props.readPosts(clubId, cursor);
  };

  const authorizeEditor = () => {
    if (!props.memberships) return false;
    const membershipClubs = props.memberships.map(m => m.club);
    const club = parseInt(props.match.params.clubId, 10);
    return membershipClubs.includes(club);
  };

  return (
    <PostsWrapper>
      {authorizeEditor() ?
        <Editor
          style={{ marginBottom: 32 }}
          onSubmit={handlePostSubmit}
        /> :
        <CardWrapper>
          <Typography variant="caption" color="primary" gutterBottom>
            로그인을 한 모임 참가자만 글을 쓸 수 있습니다.
          </Typography>
        </CardWrapper>
      }
      {props.posts.map(post => (
        <CardWrapper key={post.id}>
          <ClubPostCard post={post}/>
        </CardWrapper>
      ))}
      {props.loadingPosts &&
        <Progress/>
      }
      {props.next &&
        <div style={{ textAlign: 'center' }}>
          <Button
            color="primary"
            size="large"
            onClick={handleReadMore}
          >
            더 보기 <ExpandMoreIcon/>
          </Button>
        </div>
      }
    </PostsWrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  posts: state.board.posts,
  next: state.board.next,
  loadingPosts: state.board.loadingPosts,
  jwtPayload: state.auth.jwtPayload,
  memberships: state.membership.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readPosts, createPost },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ClubPostList) as any;
