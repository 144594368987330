import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import CommentEditor from './CommentEditor';

import { AppState } from '../../state/rootReducer';
import { AuthState } from '../../state/auth';
import { updateComment, deleteComment, Comment } from '../../state/board';

import { formatDateTime } from '../../utils/formatter';
import { askConfirmDelete } from '../../utils/confirm';

interface CommentProps extends AuthState {
  comment: Comment;
  updateComment: typeof updateComment;
  deleteComment: typeof deleteComment;
}

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

interface ActionButton {
  onClick(): void;
}

const ActionButton: React.FunctionComponent<ActionButton> = (props) => {
  return (
    <Button size="small" onClick={props.onClick} style={{ minWidth: 18 }}>
      {props.children}
    </Button>
  );
};

const CommentComponent: React.FunctionComponent<CommentProps> = (props) => {
  const [edit, setEdit] = useState(false);

  const handleSubmit = (body: string) => {
    const data = { body };
    props.updateComment(props.comment.id, data);
    handleEditClose();
  };

  const handleCommentDelete = () => {
    props.deleteComment(props.comment.id, props.comment.post);
  };

  const handleEditOpen = () => {
    setEdit(true);
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const authorizeAuthor = () => {
    return props.comment.user === (props.jwtPayload && props.jwtPayload.user_id);
  };

  return (
    <Wrapper>
      <div>
        <Typography
          variant="subtitle2"
          component="span"
          style={{ display: 'inline', marginRight: 16 }}
        >
          {props.comment.user_nickname}
        </Typography>
        {edit ?
          <CommentEditor
            onSubmit={handleSubmit}
            onCancel={handleEditClose}
            initialValue={props.comment.body}
          /> :
          <React.Fragment>
            <Typography
              variant="body1"
              component="span"
              style={{ display: 'inline' }}
            >
              {props.comment.body}
            </Typography>
            {authorizeAuthor() &&
              <span style={{ marginLeft: 16 }}>
                <ActionButton onClick={handleEditOpen}>
                  <Typography variant="caption" color="textSecondary">
                    <EditIcon fontSize="inherit"/>
                  </Typography>
                </ActionButton>
                <ActionButton onClick={askConfirmDelete(handleCommentDelete)}>
                  <Typography variant="caption" color="textSecondary">
                    <DeleteIcon fontSize="inherit"/>
                  </Typography>
                </ActionButton>
              </span>
            }
          </React.Fragment>
        }
      </div>
      <div>
        <Typography variant="caption" component="span" style={{ display: 'inline' }}>
          {formatDateTime(props.comment.created_at)}
        </Typography>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  jwtPayload: state.auth.jwtPayload,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { updateComment, deleteComment },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CommentComponent) as any;
