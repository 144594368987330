import React, { useState, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import LinkButton from './LinkButton';

import { setAccessToken } from '../../api';
import { AppState } from '../../state/rootReducer';
import { signOut, verifyToken, AuthState } from '../../state/auth';
import { readMemberships, Membership } from '../../state/membership';
import snackbar from '../../utils/snackbar';

import theme from '../../theme';
import * as strings from '../../assets/values/strings';
import LogoTextSrc from '../../assets/images/brand/logo-text.png';

const DrawerContent = styled.div`
  width: 250px;
`;

const Logo = styled.div`
  height: 48px;
  width: auto;
  text-align: center;
`;

interface HeaderProps extends RouteComponentProps, AuthState {
  signOut: typeof signOut;
  verifyToken: typeof verifyToken;
  readMemberships: typeof readMemberships;
  memberships: Membership[];
}

const drawerMenuItems = [
  { text: '소개', to: '/' },
  { text: '모임 둘러보기', to: strings.routes.clubList },
  { text: '매거진', to: strings.routes.magazineList },
  // { text: '모임 후기', to: strings.routes.reviewList },
  // { text: '도움말', to: '/faq' },
];

const toolbarMenuItems = [
  { text: '소개', to: '/' },
  { text: '모임 둘러보기', to: strings.routes.clubList },
  { text: '매거진', to: strings.routes.magazineList },
  // { text: '모임 후기', to: strings.routes.reviewList },
  // { text: '도움말', to: '/faq' },
];

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const initialized = useRef(false);

  setAccessToken(props.accessToken);

  if (!initialized.current) {
    setAccessToken(props.accessToken);
    if (props.accessToken) {
      props.verifyToken(props.accessToken);
      props.readMemberships();
    }
    initialized.current = true;
  }

  const handleSignOut = () => {
    props.signOut();
    handleActionClose();
    props.history.push('/');
    snackbar.success('로그아웃했습니다');
  };

  const handleActionOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <div>
      <AppBar position="static" color="inherit" elevation={2}>
        <Toolbar>
          <Hidden mdUp>
            <Grid container justify="space-between" alignItems="center">
              <IconButton color="inherit" aria-label="메뉴" onClick={openDrawer}>
                <MenuIcon />
              </IconButton>
              <Logo>
                <a href="/">
                  <img height="100%" src={LogoTextSrc} alt="향유고래"/>
                </a>
              </Logo>
              <div style={{ width: 48 }} />
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid container justify="center" alignItems="center">
              <Grid item md={4}>
                {toolbarMenuItems.map(item => (
                  <LinkButton key={item.text} to={item.to} size="large">
                    {item.text}
                  </LinkButton>
                ))}
              </Grid>
              <Grid item md={2}>
                <Logo>
                  <a href="/">
                    <img height="100%" src={LogoTextSrc} alt="향유고래"/>
                  </a>
                </Logo>
              </Grid>
              <Grid item md={4} style={{ textAlign: 'right' }}>
                {!props.signedIn && [
                  <LinkButton key="로그인" to="/sign_in" size="large">
                    로그인
                  </LinkButton>,
                  <LinkButton key="회원가입" to="/sign_up" size="large">
                    회원가입
                  </LinkButton>,
                ]}
                {props.signedIn &&
                <IconButton key="더 보기" onClick={handleActionOpen}>
                  <AccountCircleIcon fontSize="default"/>
                  <ExpandMoreIcon fontSize="small"/>
                </IconButton>
                }
              </Grid>
            </Grid>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleActionClose}
      >
        <List key="내 모임" subheader={<ListSubheader>내 모임</ListSubheader>}>
          {props.memberships && props.memberships.map(membership => (
            <MenuItem
              dense
              key={membership.club}
              onClick={() => {
                handleActionClose();
                props.history.push(`/clubs/${membership.club}/posts`);
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt={membership.info.name}
                  src={membership.info.image}
                />
              </ListItemAvatar>
              <ListItemText primary={membership.info.name}/>
            </MenuItem>
          ))}
          {props.memberships && props.memberships.length === 0 &&
            <Typography variant="caption" style={{ margin: 10 }}>
              아직 신청한 모임이 없습니다
            </Typography>
          }
        </List>
        <Divider/>
        <MenuItem
          dense
          onClick={() => {
            handleActionClose();
            props.history.push('/user');
          }}
        >
          <ListItemText primary="내 멤버십"/>
        </MenuItem>
        <MenuItem dense onClick={handleSignOut}>
          <ListItemText primary="로그아웃"/>
        </MenuItem>
      </Menu>
      <Drawer open={drawerOpen} onClose={closeDrawer}>
        <DrawerContent
          tabIndex={0}
          role="button"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0 8px',
              height: theme.mixins.toolbar.height,
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={closeDrawer}>
              <ChevronLeftIcon/>
            </IconButton>
          </div>
          <Divider/>
          <List>
            {drawerMenuItems.map(item => (
              <ListItem button key={item.text} onClick={() => props.history.push(item.to)}>
                <ListItemText primary={item.text}/>
              </ListItem>
            ))}
          </List>
          {!props.signedIn &&
          <React.Fragment>
            <Divider />
            <List>
              <ListItem button onClick={() => props.history.push('/sign_in/')}>
                <ListItemText primary="로그인"/>
              </ListItem>
              <ListItem button onClick={() => props.history.push('/sign_up/')}>
                <ListItemText primary="회원가입"/>
              </ListItem>
            </List>
          </React.Fragment>
          }
          {props.signedIn &&
          <React.Fragment>
            <Divider />
            <List subheader={<ListSubheader>내 모임</ListSubheader>}>
            {props.memberships && props.memberships.map(membership => (
              <ListItem
                button
                key={membership.club}
                onClick={() => props.history.push(`/clubs/${membership.club}/posts`)}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={membership.info.name}
                    src={membership.info.image}
                  />
                </ListItemAvatar>
                <ListItemText primary={membership.info.name}/>
              </ListItem>
            ))}
            {props.memberships && props.memberships.length === 0 &&
              <Typography variant="caption" style={{ margin: 10 }}>
                아직 신청한 모임이 없습니다
              </Typography>
            }
            </List>
            <Divider />
            <List>
              <ListItem button onClick={() => props.history.push('/user/')}>
                <ListItemText primary="내 멤버십"/>
              </ListItem>
            </List>
            <List style={{ position: 'absolute', bottom: 0, width: '100%' }}>
              <ListItem button onClick={handleSignOut}>
                <ListItemText primary="로그아웃"/>
              </ListItem>
            </List>
          </React.Fragment>
          }
        </DrawerContent>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  signedIn: state.auth.signedIn,
  accessToken: state.auth.accessToken,
  jwtPayload: state.auth.jwtPayload,
  memberships: state.membership.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { signOut, verifyToken, readMemberships },
  dispatch,
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)) as any;
