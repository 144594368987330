import React from 'react';
import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  margin: 60px auto;
  text-align: center;
`;

const Progress: React.FunctionComponent = () => {
  return (
    <Wrapper>
      <CircularProgress size={80}/>
    </Wrapper>
  );
};

export default Progress;
