import { fade } from '@material-ui/core/styles/colorManipulator';

/* gray scale */
export const black = '#000000';
export const white = '#ffffff';
export const transparent = 'rgba(0, 0, 0, 0)';

/* primary colors */
export const primaryBlue = '#004580';
export const secondaryBlue = '#175288';

export const primaryBlack = 'rgb(58, 54, 53)';
export const primaryRed = 'rgb(220, 85, 75)';
export const primaryOrange = 'rgb(249,174,0)';
export const primaryTeal = 'rgb(95, 180, 169)';

/* app colors */
export const appBackground = '#f5f5f5';

/* text */
export const textBlue = '#105188';
export const textBlue80 = fade(textBlue, 0.8);
export const textBlue60 = fade(textBlue, 0.6);
export const textRed = primaryRed;
export const textRed80 = fade(primaryRed, 0.8);
export const textBlack = primaryBlack;
export const textBlack80 = fade(primaryBlack, 0.8);
export const textBlack60 = fade(primaryBlack, 0.6);
export const textBlack40 = fade(primaryBlack, 0.4);
export const textBlack20 = fade(primaryBlack, 0.2);
export const textWhite = white;
export const textWhite90 = fade(white, 0.9);
export const textWhite80 = fade(white, 0.8);
export const textWhite75 = fade(white, 0.75);
export const textWhite60 = fade(white, 0.6);
export const textWhite40 = fade(white, 0.4);
