import validator from 'validator';

export const email = (value: string) => {
  if (typeof value !== 'string' || !validator.isEmail(value)) {
    return '올바른 이메일 주소를 입력해주세요';
  }
};

export const password = (value: string) => {
  if (typeof value !== 'string' || value.length < 8) {
    return '비밀번호는 8자 이상이어야 합니다';
  }
};

export const passwordCheck = (value1: string, value2: string) => {
  if (typeof value1 !== 'string' || value1 !== value2) {
    return '비밀번호를 다시 한번 확인해주세요';
  }
};

export const nickname = (value: string) => {
  if (typeof value !== 'string') {
    return '이름을 입력해주세요';
  }
};

export const phoneNumber = (value: string) => {
  if (typeof value !== 'string' || !validator.isMobilePhone(value, 'ko-KR')) {
    return '휴대전화 번호를 입력해주세요';
  }
};

export const check = (value: boolean) => {
  if (!value) {
    return '체크해주세요';
  }
};
