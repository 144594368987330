import { create, ApiResponse } from 'apisauce';
import { Action } from 'redux';

export interface ApiPromiseAction extends Action {
  payload: Promise<ApiResponse<any>>;
  meta?: any;
}

export interface ApiAction extends Action {
  payload: ApiResponse<any>;
  meta?: any;
}

const getBaseUrl = () => {
  if (process.env.REACT_APP_BUILD_MODE === 'development') {
    return 'https://dev-api.hyangyugorae.com/';
  }
  if (process.env.REACT_APP_BUILD_MODE === 'production') {
    return 'https://api.hyangyugorae.com/';
  }
  return '//localhost:8000/';
};

const apiConfig = {
  baseURL: getBaseUrl(),
  headers: { 'Content-Type': 'application/json' },
  timeout: 10000,
};

const api = create(apiConfig);

export const apiWithoutAuth = create(apiConfig);

export const s3request = create({
  baseURL: 'https://s3.ap-northeast-2.amazonaws.com/hyangyugorae/',
});

export const setAccessToken = (accessToken: string | undefined) => {
  if (accessToken) {
    api.setHeader('Authorization', `Bearer ${accessToken}`);
  } else {
    api.deleteHeader('Authorization');
  }
};

export default api;
