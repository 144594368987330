import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinkButton from '../base/LinkButton';
import TextField from '../form/TextField';
import Checkbox from '../form/Checkbox';

import * as validator from '../../utils/formValidator';
import * as styles from '../../assets/values/styles';
import * as colors from '../../assets/values/colors';

const validate = (values: SignUpFormData) => {
  const errors: any = {};
  errors.password_check = validator.passwordCheck(values.password_check, values.password);
  return errors;
};

export interface SignUpFormData {
  email: string;
  nickname: string;
  password: string;
  password_check: string;
  terms: boolean;
  privacy: boolean;
}

const SignUpForm: React.FunctionComponent<InjectedFormProps<SignUpFormData>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div>
        <Field
          name="email"
          component={TextField}
          label="이메일"
          margin="dense"
          validate={[validator.email]}
          fullWidth
        />
      </div>
      <div style={{ marginTop: 8 }}>
        <Field
          name="password"
          component={TextField}
          label="비밀번호"
          type="password"
          margin="dense"
          validate={[validator.password]}
          fullWidth
        />
      </div>
      <div>
        <Field
          name="password_check"
          component={TextField}
          label="비밀번호 확인"
          type="password"
          margin="dense"
          fullWidth
        />
      </div>
      <div style={{ marginTop: 8 }}>
        <Field
          name="nickname"
          component={TextField}
          label="이름"
          margin="dense"
          validate={[validator.nickname]}
          fullWidth
        />
      </div>
      <div style={{ marginTop: 8 }}>
        <Grid container alignItems="center">
          <Grid xs={8}>
            <Field
              name="terms"
              component={Checkbox}
              label="이용약관에 동의합니다"
              validate={[validator.check]}
            />
          </Grid>
          <Grid xs={4} style={{ textAlign: 'right' }}>
            <LinkButton to="/terms" target="blank" size="small">
              자세히 보기
            </LinkButton>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container alignItems="center">
          <Grid xs={8}>
            <Field
              name="privacy"
              component={Checkbox}
              label="개인정보취급방침에 동의합니다"
              validate={[validator.check]}
            />
          </Grid>
          <Grid xs={4} style={{ textAlign: 'right' }}>
            <LinkButton to="/privacy" target="blank" size="small">
              자세히 보기
            </LinkButton>
          </Grid>
        </Grid>
      </div>
      <table
        style={{
          width: '100%',
          fontSize: styles.fontSize.small,
          color: colors.textBlack80,
          padding: 5,
          borderSpacing: 5,
        }}
      >
        <tbody>
          <tr>
            <th>이용목적</th><td>본인확인, 공지사항 전달 및 부정이용 방지</td>
          </tr>
          <tr>
            <th>수집항목</th><td>성명 또는 닉네임, 이메일</td>
          </tr>
          <tr>
            <th>보유기간</th><td>회원탈퇴시 까지</td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginTop: 8 }}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={props.submitting}
        >
          가입하기
        </Button>
      </div>
    </form>
  );
};

export default reduxForm<SignUpFormData>({
  validate,
  form: 'signUpForm',
})(SignUpForm);
