import React from 'react';

import Typography from '@material-ui/core/Typography';

interface PostBodyProps {
  content: string;
}

const PostBody: React.FunctionComponent<PostBodyProps> = (props) => {
  return (
    <Typography component="div" style={{ margin: -15 }}>
      <div
        dangerouslySetInnerHTML={{ __html: props.content }}
        className="ql-editor"
      />
    </Typography>
  );
};

export default PostBody;
