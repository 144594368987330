import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';

import ForgotPasswordForm from './ForgotPasswordForm';

import { AuthState } from '../../state/auth';
import { requestPasswordResetToken, UserState } from '../../state/user';
import { AppState } from '../../state/rootReducer';

const Wrapper = styled.div`
  margin: 60px auto;
  max-width: 400px;
  padding: 0 16px;
`;

interface ForgotPasswordProps {
  requestPasswordResetToken: typeof requestPasswordResetToken;
}

class ForgotPassword extends React.Component<ForgotPasswordProps & UserState & AuthState
& RouteComponentProps> {

  componentWillMount() {
    if (this.props.signedIn) {
      this.props.history.goBack();
    }
  }

  handleSubmit = (data: object) => {
    return this.props.requestPasswordResetToken(data);
  }

  render() {
    return (
      <Wrapper>
        <Helmet
          title="비밀번호 변경 | 향유고래"
        />
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5">
              비밀번호 변경
            </Typography>
            <Typography gutterBottom variant="body1">
              회원가입할 때 사용하셨던 이메일 주소를 적어주시면, 해당 이메일 주소로 비밀번호를 변경할 수 있는 링크를 보내드립니다.
            </Typography>
            {this.props.passwordResetTokenRequested ?
              <div>
                <Typography align="center" color="primary">
                  <DoneIcon style={{ fontSize: 100 }}/>
                </Typography>
                <Typography align="center" variant="subtitle1">이메일을 확인해주세요!</Typography>
              </div> :
              <ForgotPasswordForm onSubmit={this.handleSubmit}/>
            }
          </CardContent>
        </Card>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  signedIn: state.auth.signedIn,
  passwordResetTokenRequested: state.user.passwordResetTokenRequested,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { requestPasswordResetToken },
  dispatch,
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));
