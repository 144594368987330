import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

interface MuiSelectProps extends WrappedFieldProps {
  label: string;
}

const MuiSelect: React.FunctionComponent<MuiSelectProps> = ({
  input,
  label,
  meta: { touched, invalid, error },
  ...props
}) => (
  <FormControl
    error={touched && invalid}
  >
    <InputLabel>{label}</InputLabel>
    <Select
      native
      value={input.value}
      onChange={input.onChange}
      inputProps={input}
    >
      {props.children}
    </Select>
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

export default MuiSelect;
