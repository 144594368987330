import React, { useState } from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const ButtonWrapper = styled.div`
  margin-top: 12px;
`;

export interface SignInFormData {
  email: string;
  password: string;
}

interface SignInFormProps {
  onSubmit(data: SignInFormData): void;
  submitting: boolean;
}

const SignInForm: React.FunctionComponent<SignInFormProps> = (props) => {
  const { submitting, onSubmit } = props;
  const [data, setData] = useState<SignInFormData>({
    email: '',
    password: '',
  });

  const handleChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [key]: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <TextField
          label="이메일"
          margin="dense"
          onChange={handleChange('email')}
          variant="outlined"
          fullWidth
        />
      </div>
      <div>
        <TextField
          label="비밀번호"
          margin="dense"
          onChange={handleChange('password')}
          variant="outlined"
          type="password"
          fullWidth
        />
      </div>
      <ButtonWrapper>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={submitting}
          fullWidth
        >
          로그인
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default SignInForm;
