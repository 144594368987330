import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { Review } from '../../state/review';

interface ReviewCardProps {
  review: Review;
}

const ReviewCard: React.FunctionComponent<ReviewCardProps> = (props) => {
  return (
    <Card>
      <CardHeader
        title={<small>{props.review.title}</small>}
        subheader={
          <span>
            [{props.review.club_name}] {props.review.user_nickname}님의 후기
          </span>
        }
      />
      <CardContent>
        <Typography variant="body1">
          {props.review.body}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
