import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import Share from '../utility/Share';

import * as colors from '../../assets/values/colors';

const MarkdownWrapper = styled.div`
  margin: 60px auto;
  max-width: 700px;
  color: ${colors.textBlue};
  line-height: 1.75;
`;

const Spacer = styled.div`
  height: 16px;
`;

interface ClubDescriptionProps {
  description?: string;
  name?: string;
  subtitle?: string;
}

const ClubDescription: React.FunctionComponent<ClubDescriptionProps> = (props) => {
  return (
    <MarkdownWrapper>
      <ReactMarkdown
        source={props.description}
        escapeHtml={false}
      />
      <Spacer/>
      <Share
        url={document.location.href}
        text={`${props.name}: ${props.subtitle}`}
      />
    </MarkdownWrapper>
  );
};

export default ClubDescription;
