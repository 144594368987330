import { ApiResponse } from 'apisauce';
import api from '../api';

interface AssetResponseData {
  id: number;
  user?: number;
  file: string;
  created_at: string;
}

export const uploadFile = async (file: File, user?: number) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  if (user) formData.append('user', `${user}`);

  const request: Promise<ApiResponse<AssetResponseData>> = api.post(
    '/assets/',
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );

  return request.then((response) => {
    if (response.data) {
      return response.data.file;
    }
    return '파일 업로드를 실패했습니다.';
  }).catch(() => {
    return '파일을 업로드하지 못했습니다.';
  });
};

export const uploadImage = (image: File, user?: number) => {
  return uploadFile(image, user);
};
