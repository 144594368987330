import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface CheckboxProps extends WrappedFieldProps {
  label: string;
}

const MuiCheckbox: React.FunctionComponent<CheckboxProps> = ({
  input,
  label,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormControl
    error={touched && invalid}
  >
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

export default MuiCheckbox;
