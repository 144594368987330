import React, { useRef } from 'react';
import { match } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ClubPostCard from './ClubPostCard';
import LinkButton from '../base/LinkButton';

import { readPost, BoardState } from '../../state/board';
import { AppState } from '../../state/rootReducer';

interface ClubPostDetailProps extends BoardState {
  readPost: typeof readPost;
  match: match<{ clubId: string, postId: string }>;
}

const PostsWrapper = styled.div`
  margin: 60px auto;
  max-width: 700px;
`;

const ClubPostDetail: React.FunctionComponent<ClubPostDetailProps> = (props) => {

  const initialized = useRef(false);
  const clubId = parseInt(props.match.params.clubId, 10);

  if (!initialized.current) {
    const postId = parseInt(props.match.params.postId, 10);
    props.readPost(postId);
    initialized.current = true;
  }

  return (
    <PostsWrapper>
      <LinkButton
        to={`/clubs/${clubId}/posts`}
        color="primary"
        style={{ marginBottom: 16 }}
      >
        <ChevronLeftIcon style={{ marginTop: 2 }}/>목록으로 돌아가기
      </LinkButton>
      {props.post && <ClubPostCard raised post={props.post}/>}
      <LinkButton
        to={`/clubs/${clubId}/posts`}
        color="primary"
        style={{ marginBottom: 16, marginTop: 16 }}
      >
        <ChevronLeftIcon style={{ marginTop: 2 }}/>목록으로 돌아가기
      </LinkButton>
    </PostsWrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  post: state.board.post,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readPost },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ClubPostDetail) as any;
