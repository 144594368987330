import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Select from '../form/Select';
import TextField from '../form/TextField';

import { ReviewFormData } from '../../state/review';
import { Membership } from '../../state/membership';

interface ReviewFormProps {
  memberships: Membership[];
  style?: object;
}

const FieldRow = styled.div`
  margin-bottom: 8px;
`;

const ReviewForm: React.FunctionComponent<
  ReviewFormProps & InjectedFormProps<ReviewFormData, ReviewFormProps>
> = (props) => {
  const { handleSubmit, submitting, style } = props;

  return (
    <form onSubmit={handleSubmit} style={style}>
      <Field
        name="user"
        component="input"
        type="hidden"
      />
      <FieldRow>
        <Field
          name="club"
          component={Select}
          label="모임"
        >
          <option value="" />
          {props.memberships.map(
            membership => (
              <option key={membership.club} value={membership.club}>
                {membership.info.name}
              </option>
            ),
          )}
        </Field>
      </FieldRow>
      <FieldRow>
        <Field
          name="title"
          component={TextField}
          label="제목"
          margin="dense"
          variant="outlined"
          fullWidth
        />
      </FieldRow>
      <FieldRow>
        <Field
          name="body"
          component={TextField}
          margin="dense"
          variant="outlined"
          fullWidth
          multiline
          rows={6}
        />
      </FieldRow>
      <Button
        type="submit"
        variant="outlined"
        color="primary"
        disabled={submitting}
      >
        쓰기
      </Button>
    </form>
  );
};

export default reduxForm<ReviewFormData, ReviewFormProps>({
  form: 'reviewForm',
})(ReviewForm);
