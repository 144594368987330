import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';

import LinkButton from '../base/LinkButton';
import Progress from '../base/Progress';

import { Club } from '../../state/club';
import { Membership } from '../../state/membership';

import { formatKRW } from '../../utils/formatter';

interface MembershipItemProps extends RouteComponentProps {
  membership?: Membership;
  club?: Club;
}

const MembershipItem: React.FunctionComponent<MembershipItemProps> = (props) => {
  const club = props.membership ? props.membership.info : props.club;
  if (!club) {
    return (
      <Card>
        <Progress/>
      </Card>
    );
  }
  return (
    <Card>
      <CardMedia
        style={{
          height: 0,
          paddingTop: '100%',
        }}
        image={club.image}
        title={club.name}
      />
      <CardContent>
        <Typography variant="h5">
          {club.name}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {club.subtitle}
        </Typography>
        <Typography variant="body1">
          {club.short_description}
        </Typography>
      </CardContent>
      <CardActions>
        <LinkButton
          to={`/clubs/${club.id}/posts`}
          color="primary"
        >
          모임 커뮤니티 가기
        </LinkButton>
      </CardActions>
      {props.membership &&
        <div>
          <Divider/>
          <CardContent>
            <Typography variant="subtitle1">
              결제 기록
            </Typography>
          </CardContent>
          <List>
            {props.membership.transactions.map(transaction => (
              <ListItem key={transaction.id}>
                <ListItemText
                  primary={formatKRW(transaction.amount)}
                  secondary={
                    <React.Fragment>
                      <Typography variant="caption" component="span">
                        {format(parse(transaction.created_at), 'YYYY년 MM월 DD일 결제')}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <LinkButton
                    to={transaction.receipt_url}
                    size="small"
                    target="blank"
                    external
                  >
                    영수증 보기
                  </LinkButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      }
    </Card>
  );
};

export default withRouter(MembershipItem);
