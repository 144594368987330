import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinkButton from '../base/LinkButton';

import SignInForm from './SignInForm';

import { signIn } from '../../state/auth';
import { AppState } from '../../state/rootReducer';
import snackbar from '../../utils/snackbar';

const Wrapper = styled.div`
  margin: 60px auto;
  max-width: 400px;
  padding: 0 16px;
`;

const SignUpHelper = styled.div`
  margin: 16px 0px;
`;

const Forgot = styled.div`
  margin-top: 16px;
`;

const SignIn: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { signedIn, signInError, submitting } = useSelector((state: AppState) => state.auth);

  const handleSubmit = (data: object) => {
    return dispatch(signIn(data));
  };

  useEffect(
    () => {
      if (signedIn) {
        snackbar.success('로그인을 성공했습니다');
        history.goBack();
      }
      if (signInError) {
        snackbar.error('로그인을 실패했습니다');
      }
    },
    [signedIn, signInError, history],
  );

  return (
    <Wrapper>
      <Helmet
        title="로그인 | 향유고래"
      />
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            로그인
          </Typography>
          <SignInForm
            onSubmit={handleSubmit}
            submitting={submitting}
          />
          <SignUpHelper>
            <Typography variant="body2" align="center">
              아직 회원이 아니신가요?
            </Typography>
          </SignUpHelper>
          <LinkButton
            variant="outlined"
            fullWidth
            to="/sign_up"
          >
            가입하기
          </LinkButton>
          <Forgot>
            <LinkButton
              size="small"
              to="/forgot_password"
            >
              비밀번호를 잊어버렸어요
            </LinkButton>
          </Forgot>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default SignIn;
