import jwt from 'jsonwebtoken';

import api, { setAccessToken, apiWithoutAuth, ApiPromiseAction, ApiAction } from '../api';
import { fulfilled, pending } from './helper';

const SIGN_UP = 'auth/signUp';
const SIGN_IN = 'auth/signIn';
const SIGN_OUT = 'auth/signOut';
const VERIFY_TOKEN = 'auth/verifyToken';

export function signUp(data: object): ApiPromiseAction {
  const request = api.post('/users/', data);
  return {
    type: SIGN_UP,
    payload: request,
  };
}

export function signIn(data: object): ApiPromiseAction {
  const request = api.post('/token/', data);
  return {
    type: SIGN_IN,
    payload: request,
  };
}

export function verifyToken(token: string): ApiPromiseAction {
  const request = apiWithoutAuth.post('/token/verify/', { token });
  return {
    type: VERIFY_TOKEN,
    payload: request,
  };
}

export function signOut() {
  return {
    type: SIGN_OUT,
  };
}

export interface JwtPayload {
  user_id: number;
}

export interface AuthState {
  signedUp: boolean;
  signUpError: string | null;
  signedIn: boolean;
  signInError: string | null;
  accessToken: string | undefined;
  jwtPayload: JwtPayload | undefined;
  submitting: boolean;
}

const INITIAL_AUTH_STATE: AuthState = {
  signedUp: false,
  signUpError: null,
  signedIn: false,
  signInError: null,
  accessToken: undefined,
  jwtPayload: undefined,
  submitting: false,
};

export default function (state: AuthState = INITIAL_AUTH_STATE, action: ApiAction): AuthState {
  switch (action.type) {
    case pending(SIGN_UP): {
      return {
        ...state,
        signedUp: false,
        signUpError: null,
      };
    }
    case fulfilled(SIGN_UP): {
      return {
        ...state,
        signedUp: action.payload.ok,
        signUpError: action.payload.problem,
      };
    }
    case pending(SIGN_IN): {
      return {
        ...state,
        signedIn: false,
        signInError: null,
        submitting: true,
      };
    }
    case fulfilled(SIGN_IN): {
      const accessToken = action.payload.data && action.payload.data.access;
      const jwtPayload = jwt.decode(accessToken) as JwtPayload;
      setAccessToken(accessToken);
      return {
        ...state,
        accessToken,
        jwtPayload,
        signedIn: action.payload.ok,
        signInError: action.payload.problem,
        submitting: false,
      };
    }
    case SIGN_OUT: {
      setAccessToken(undefined);
      return INITIAL_AUTH_STATE;
    }
    case fulfilled(VERIFY_TOKEN): {
      if (!action.payload.ok) {
        setAccessToken(undefined);
        return INITIAL_AUTH_STATE;
      }

      return state;
    }
    default: {
      return state;
    }
  }
}
