import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { AuthState } from '../../state/auth';

import * as strings from '../../assets/values/strings';
import { AppState } from '../../state/rootReducer';

const Tracker: React.FunctionComponent<AuthState & RouteComponentProps> = (props) => {
  const initializedRef = useRef(false);

  const [page, setPage] = useState(props.location.pathname + props.location.search);
  const prevPageRef = useRef(page);

  const trackPage = useCallback(
    (path: string, options = {}) => {
      ReactGA.set({
        path,
        ...options,
      });
      ReactGA.pageview(page);
    },
    [page],
  );

  useEffect(
    () => {
      if (!initializedRef.current) {
        ReactGA.initialize(
          strings.googleAnalyticsID,
          { debug: process.env.NODE_ENV !== 'production' },
        );

        if (props.jwtPayload) {
          const userId = props.jwtPayload.user_id;
          ReactGA.set({ userId });
          fbq('init', strings.facebookPixelId, { uid: userId });
        } else {
          fbq('init', strings.facebookPixelId);
        }

        trackPage(page);
        fbq('track', 'PageView');

        initializedRef.current = true;
      }

      const currentPage = props.location.pathname + props.location.search;
      if (currentPage !== prevPageRef.current) {
        trackPage(page);
        fbq('track', 'PageView');
        prevPageRef.current = currentPage;
      }
      setPage(currentPage);
    },
    [props.location.pathname, props.location.search, props.jwtPayload, trackPage, page],
  );

  return(
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  jwtPayload: state.auth.jwtPayload,
});

export default withRouter(connect(mapStateToProps)(Tracker)) as any;
