export enum fontSize {
  extraSmall = '10px',
  small = '12px',
  medium = '14px',
  large = '18px',
  extraLarge = '28px',
}

export enum fontWeight {
  light = 300,
  normal = 400,
  bold = 500,
}

export const footerHeight = '250px';
