import React from 'react';
import styled from 'styled-components';
import LinkButton from '../base/LinkButton';

const Wrapper = styled.div`
  margin: 60px 0;
  text-align: center;
`;

const RouterError: React.FunctionComponent = () => (
  <Wrapper>
    <div style={{ fontSize: 120 }}>?_?</div>
    <div style={{ fontSize: 18 }}>찾으시는 페이지가 없습니다</div>
    <div style={{ marginTop: 30 }}>
      <LinkButton
        to="/"
        variant="outlined"
      >
        홈으로 가기
      </LinkButton>
    </div>
  </Wrapper>
);

export default RouterError;
