import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinkButton from '../base/LinkButton';
import { getClubFilter } from '../club/ClubList';
import ClubListItem from '../club/ClubListItem';
import IntroductionItem from './IntroductionItem';
import Section from './Section';
import ComingSoon from '../utility/ComingSoon';
import Wanted from './Wanted';

import theme from '../../theme';
import * as colors from '../../assets/values/colors';

import illustrationSrc from '../../assets/images/landing/illustration.png';
import logoSrc from '../../assets/images/brand/logo.png';
import drawingSrc from '../../assets/images/landing/drawing.jpg';
import goalSrc from '../../assets/images/landing/goal.png';
import howSrc from '../../assets/images/landing/how.png';
import whoSrc from '../../assets/images/landing/who.png';
import artistSrc from '../../assets/images/landing/artist.png';
// import peoSrc from '../../assets/images/landing/peo.png';
import editorSrc from '../../assets/images/landing/editor.png';
import placeSrc from '../../assets/images/landing/place.png';
import cowndogSrc from '../../assets/images/landing/cowndog.png';
import placevibSrc from '../../assets/images/landing/placevib.png';

import { initializeList, readClubs, ClubState } from '../../state/club';
import { AppState } from '../../state/rootReducer';

const HeadingSection = styled.div`
  background-image: url(${illustrationSrc});
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  padding: 0 16px;
  max-height: 90vh;
  box-sizing: content-box;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    background-position-y: -19vh;
  }
`;

const Logo = styled.div`
  height: 90px;
  margin-top: 100px;
  margin-bottom: 50px;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    height: 70px;
    margin-top: 60vw;
    margin-bottom: 16px;
  }
`;

const Spacer = styled.div`
  height: 3vw;
`;

const LineBlock = styled.div`
  display: inline-block;
`;

const Catchphrase = styled.div`
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 16px;
  color: ${colors.textBlue};
`;

const HeadingCTA = styled.div`
  margin-top: 24px;
  margin-bottom: 200px;

  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 48px;
  }
`;

const ImageSection = styled.div`
  background-color: rgba(8, 54, 95, 0.73);
  background-image: linear-gradient(119deg, rgba(32, 154, 214, 0), #044882), url(${drawingSrc});
  background-size: cover;
  background-position: center;
  padding: 10vh 16px;
  color: ${colors.textWhite};
  text-align: center;
`;

const ImageTextSmall = styled.div`
  font-size: 18px;
  line-height: 2;
  color: ${colors.textWhite};
  text-align: center;
`;

const ImageTextMedium = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.textWhite};
  text-align: center;
  margin-top: 48px;
`;

const ImageTextLarge = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.textWhite};
  line-height: 1.7;
  text-align: center;
  margin-top: 50px;
`;

const PartnerLogo = styled.img`
  display: block;
  margin: 16px auto;
`;

interface HomeProps {
  initializeList: typeof initializeList;
  readClubs: typeof readClubs;
}

const Home: React.FunctionComponent<HomeProps & ClubState & RouteComponentProps> = (props) => {

  const [initialized, setInitialized] = useState(false);

  if (!initialized) {
    props.readClubs(getClubFilter(0));
    setInitialized(true);
  }

  return (
    <div>
      <HeadingSection>
        <Grid container justify="center">
          <Grid item xs={12} lg={8}>
            <Logo>
              <img src={logoSrc} height="100%" width="auto" alt="향유고래"/>
            </Logo>
            <Catchphrase>
              <LineBlock>“당신의 삶에</LineBlock> <LineBlock>예술적 영감을 불어 넣으세요”</LineBlock>
            </Catchphrase>
            <Typography variant="h6" color="primary">
              문화예술을 함께 향유하는 모임
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={false} lg={2}></Grid>
          <Grid item xs={12} md={2}>
            <HeadingCTA>
              <LinkButton
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                to="/clubs"
                style={{ padding: '12px 0px' }}
              >
                지금 시작하기
              </LinkButton>
            </HeadingCTA>
          </Grid>
        </Grid>
      </HeadingSection>
      <ImageSection>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <ImageTextSmall>
              당신은 예술작품을 감상하고<br/>
              <LineBlock>예술적 영감으로 충만해지는 경험을</LineBlock> <LineBlock>한 적이 있나요?</LineBlock><br/><br/>
              <LineBlock>라디오에서 갑자기 좋아하는 노래가</LineBlock> <LineBlock>흘러나왔을 때</LineBlock><br/>
              <LineBlock>무심결에 지나치는 카페의 그림이</LineBlock> <LineBlock>너무 예뻤을 때</LineBlock>
            </ImageTextSmall>
            <ImageTextMedium>
              그 때 당신은 어땠나요?
            </ImageTextMedium>
            <ImageTextLarge>
              <LineBlock>당신의 삶은 예술적 영감이</LineBlock> <LineBlock>함께할 때 가장 빛나요.</LineBlock><br/>
              우리 같이해요. 같이 향유해요.
            </ImageTextLarge>
          </Grid>
        </Grid>
      </ImageSection>
      <Section color="white" title="향유고래 모임에서는">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <IntroductionItem
              color={colors.textBlue}
              align="center"
              image={whoSrc}
              title="Who"
              description="문화예술을 사랑하는 방법을 알아가고 싶은 사람"
              phrase="“향유고래는 예술의 다양성을 포용하고 향유할 줄 아는 사람들이 모입니다.”"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <IntroductionItem
              color={colors.textBlue}
              align="center"
              image={howSrc}
              title="How"
              description="다 같이 모여 문화예술을 보고 듣고 이야기합니다."
              phrase="“향유고래에서는 깊은 이야기를 나누며 문화예술을 좀 더 사랑하는 방법을 배웁니다.”"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <IntroductionItem
              color={colors.textBlue}
              align="center"
              image={goalSrc}
              title="Goal"
              description="깊은 향유를 통해 예술적 영감을 얻어갑니다."
              phrase="“향유고래는 모두의 삶에 예술적 영감이 함께하길 바랍니다.”"
            />
          </Grid>
        </Grid>
      </Section>
      <Section color="primary" title="이런 사람들과 함께 향유합니다">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <IntroductionItem
              color={colors.textWhite}
              align="left"
              image={placeSrc}
              title="참여자"
              description="모임에 참여하여 문화예술을 향유합니다. 모임을 통해 예술에 몰입하고 이를 능동적으로 즐길 수 있는 능력을 기를 수 있습니다."
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <IntroductionItem
              color={colors.textWhite}
              align="left"
              image={editorSrc}
              title="기획자"
              description="좋아하는 예술을 남다른 방식으로 즐길 수 있는 능력을 갖춘 사람들입니다.
                모임을 주도하면서 사람들과 좋아하는 예술을 공유할 수 있습니다."
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <IntroductionItem
              color={colors.textWhite}
              align="left"
              image={artistSrc}
              title="아티스트"
              description="참여자들이 더 깊이있게 예술을 향유 할 수 있도록 도와주고 소통합니다."
            />
          </Grid>
        </Grid>
      </Section>
      <Section color="white" title="현재 모집하고 있는 모임">
        <Spacer/>
        <Grid container spacing={3} justify="center">
        {props.list && props.list.length === 0 &&
          <ComingSoon text="아직 모임이 없습니다"/>
        }
        {props.list && props.list.length > 0 && props.list.map(club => (
          <Grid item xs={12} md={4} key={club.id}>
            <ClubListItem
              onClick={() => props.history.push(`/clubs/${club.id}`)}
              club={club}
            />
          </Grid>
        ))}
        </Grid>
        <Spacer/>
      </Section>
      <Section color="primary" title="모임 장소" subtitle="향유고래는 개성있는 다양한 공간과 함께합니다">
        <Spacer/>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <a href="http://www.placevib.com" target="blank">
              <PartnerLogo height={60} src={placevibSrc}/>
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a href="http://www.cowndog.com" target="blank">
              <PartnerLogo height={60} src={cowndogSrc}/>
            </a>
          </Grid>
        </Grid>
        <Spacer/>
      </Section>
      <Wanted/>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  list: state.club.list,
  loading: state.club.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { initializeList, readClubs },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
