import React, { useEffect, ChangeEvent, useState } from 'react';
import { match, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Progress from '../base/Progress';
import SignMixin from '../auth/SignMixin';
import ClubInfo from '../club/ClubInfo';

import { JwtPayload } from '../../state/auth';
import { resetPaymentData, setPaymentData, startCheckout,
  setBillingData, resetBillingData, createBillingTransaction,
  PaymentState, PaymentData } from '../../state/payment';
import { readClubDetail, ClubState } from '../../state/club';
import { AppState } from '../../state/rootReducer';

import { requestPay, generateInitialPaymentData } from '../../utils/billing';
import LinkButton from '../base/LinkButton';

import * as strings from '../../assets/values/strings';
import snackbar from '../../utils/snackbar';

const Wrapper = styled.div`
  margin: 60px 16px;
`;

const Spacer = styled.div`
  height: 16px;
`;

const Field = styled.div`
  margin-top: 8px;
`;

interface PaymentDialogProps {
  jwtPayload: JwtPayload | undefined;
  signedIn: boolean;
  readClubDetail: typeof readClubDetail;
  setPaymentData: typeof setPaymentData;
  setBillingData: typeof setBillingData;
  resetBillingData: typeof resetBillingData;
  resetPaymentData: typeof resetPaymentData;
  createBillingTransaction: typeof createBillingTransaction;
  match: match<{clubId: string }>;
}

enum PaymentMethod {
  PG = 'pg',
  BILLING = 'billing',
}

const expiryYears = [
  {
    value: '',
    label: '',
  },
  {
    value: '2019',
    label: '2019',
  },
  {
    value: '2020',
    label: '2020',
  },
  {
    value: '2021',
    label: '2021',
  },
  {
    value: '2022',
    label: '2022',
  },
  {
    value: '2023',
    label: '2023',
  },
  {
    value: '2024',
    label: '2024',
  },
  {
    value: '2025',
    label: '2025',
  },
  {
    value: '2026',
    label: '2026',
  },
];

const expiryMonths = [
  {
    value: '',
    label: '',
  },
  {
    value: '01',
    label: '01',
  },
  {
    value: '02',
    label: '02',
  },
  {
    value: '03',
    label: '03',
  },
  {
    value: '04',
    label: '04',
  },
  {
    value: '05',
    label: '05',
  },
  {
    value: '06',
    label: '06',
  },
  {
    value: '07',
    label: '07',
  },
  {
    value: '08',
    label: '08',
  },
  {
    value: '09',
    label: '09',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '11',
    label: '11',
  },
  {
    value: '12',
    label: '12',
  },
];

const cardQuotas = [
  {
    value: 1,
    label: '일시불',
  },
  {
    value: 2,
    label: '2개월',
  },
  {
    value: 3,
    label: '3개월',
  },
  {
    value: 4,
    label: '4개월',
  },
  {
    value: 5,
    label: '5개월',
  },{
    value: 6,
    label: '6개월',
  },
];

const PaymentDialog: React.FunctionComponent<PaymentDialogProps & PaymentState
& ClubState & RouteComponentProps> = (props) => {

  // TODO: 한가지 결제수단으로 통일하기
  const [paymentMethod] = useState<PaymentMethod>(PaymentMethod.BILLING);

  useEffect(() => {
    const clubId = parseInt(props.match.params.clubId, 10);
    if (!props.detail || props.detail.id !== clubId) {
      props.readClubDetail(clubId);
      return;
    }

    if (!props.data || (props.data && props.data.custom_data.club_id !== props.detail.id)) {
      const data = generateInitialPaymentData(props.detail);
      props.setPaymentData(data);
      props.setBillingData(data as any);
      return;
    }

    if (!props.data.custom_data.user_id && props.jwtPayload) {
      const newData: PaymentData = {
        ...props.data,
        custom_data: {
          ...props.data.custom_data,
          user_id: props.jwtPayload.user_id,
        },
      };
      props.setPaymentData(newData);
      props.setBillingData(newData as any);
    }
  });

  useEffect(
    () => {
      if (props.billingError) {
        snackbar.error(props.billingError);
      }

      if (props.billingResponse) {
        alert(props.billingResponse);
      }
    },
    [props.billingError, props.billingResponse],
  );

  function replaceRange(s: string, start: number, end:number, substitute: string) {
    return s.substring(0, start) + substitute + s.substring(end);
  }

  const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    if (!props.data || !props.billingData) return;

    let entities = {};
    if (['expiry_year', 'expiry_month'].includes(name)) {
      let expiry = props.billingData.expiry || 'YYYY-MM';
      if (name === 'expiry_year') {
        expiry = replaceRange(expiry, 0, 4, event.currentTarget.value);
      }
      if (name === 'expiry_month') {
        expiry = replaceRange(expiry, 5, 7, event.currentTarget.value);
      }
      entities = { expiry };
    } else {
      entities = { [name]: event.currentTarget.value };
    }

    const newData: any = {
      ...props.data,
      ...props.billingData,
      ...entities,
    };

    if (paymentMethod === PaymentMethod.BILLING) {
      props.setBillingData(newData);
    } else {
      props.setPaymentData(newData);
    }
  };

  const handlePurchase = () => {
    if (!props.data) return;

    const data = props.data;
    if (!data.buyer_name && data.buyer_email) {
      data.buyer_name = data.buyer_email;
    }

    if (paymentMethod === PaymentMethod.BILLING && props.billingData) {
      props.createBillingTransaction(props.billingData);
    } else {
      requestPay(props.data);
    }
  };

  if (!props.detail || !props.data) {
    return <Progress/>;
  }

  return (
    <Wrapper>
      <Grid container justify="center">
        <Grid item xs={12} lg={4}>
          <Typography gutterBottom variant="h4" color="textPrimary" style={{ fontWeight: 700 }}>
            모임 신청하기
          </Typography>
          <Spacer/>
          {props.data && props.detail && (
            <Card>
              <CardMedia
                style={{
                  height: 0,
                  paddingTop: '100%',
                }}
                image={props.detail.image}
                title={props.detail.name}
              />
              <CardContent>
                <ClubInfo
                  detail={props.detail}
                  showPrice
                />
              </CardContent>
            </Card>
          )}
          <Spacer/>
          {!props.signedIn &&
          <Card>
            <CardContent>
              <Typography gutterBottom variant="subtitle1">
                모임 신청을 위해 로그인이 필요합니다
              </Typography>
              <SignMixin/>
            </CardContent>
          </Card>
          }
          {props.signedIn &&
          <div>
            <Card>
              <CardContent>
                <Typography variant="h6" color="primary">
                  연락처
                </Typography>
                <Field>
                  <TextField
                    name="buyer_tel"
                    label="휴대전화 번호"
                    helperText="모임 진행을 위해 연락받을 수 있는 휴대전화 번호를 적어주세요"
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange('buyer_tel')}
                    fullWidth
                  />
                </Field>
                <Field>
                  <TextField
                    name="buyer_email"
                    label="이메일"
                    helperText="연락받을 수 있는 이메일 주소를 적어주세요"
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange('buyer_email')}
                    fullWidth
                  />
                </Field>
                {paymentMethod === PaymentMethod.BILLING &&
                <>
                  <Spacer/>
                  <Typography variant="h6" color="primary">
                    카드 정보
                  </Typography>
                  <Field>
                    <TextField
                      name="card_number"
                      label="카드번호"
                      helperText="결제에 사용할 카드번호를 입력해주세요"
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange('card_number')}
                      fullWidth
                    />
                  </Field>
                  <Field>
                    <TextField
                      select
                      name="expiry_year"
                      label="YYYY"
                      helperText="유효기간 연도"
                      variant="outlined"
                      margin="dense"
                      SelectProps={{
                        native: true,
                      }}
                      onChange={handleChange('expiry_year')}
                      style={{
                        width: '8em',
                      }}
                    >
                      {expiryYears.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      select
                      name="expiry_month"
                      label="MM"
                      helperText="유효기간 월"
                      variant="outlined"
                      margin="dense"
                      SelectProps={{
                        native: true,
                      }}
                      onChange={handleChange('expiry_month')}
                      style={{
                        width: '8em',
                        marginLeft: '2em',
                      }}
                    >
                      {expiryMonths.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Field>
                  <Field>
                    <TextField
                      name="birth"
                      label="생년월일"
                      helperText="카드 소유주의 생년월일 6자리를 입력해주세요"
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange('birth')}
                    />
                  </Field>
                  <Field>
                    <TextField
                      name="pwd_2digit"
                      label="비밀번호 앞 2자리"
                      helperText="카드 비밀번호 앞 2자리를 입력해주세요"
                      variant="outlined"
                      margin="dense"
                      type="password"
                      onChange={handleChange('pwd_2digit')}
                    />
                  </Field>
                  {props.billingData && props.billingData.amount >= 50000 &&
                  <Field>
                    <TextField
                      select
                      name="card_quota"
                      label="할부"
                      helperText="할부 개월 수를 선택해주세요"
                      variant="outlined"
                      margin="dense"
                      onChange={handleChange('card_quota')}
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {cardQuotas.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Field>
                  }
                  <Spacer/>
                  <Typography variant="caption">
                    향유고래는 SSL 보안통신을 사용하여 사용자와 서버간의 연결을 안전하게 암호화하고 있습니다.
                    또한 사용자의 카드정보는 결제대행사에서 관리하고 있으며, 향유고래 서버에 보관되지 않습니다.
                  </Typography>
                  <Spacer/>
                  <LinkButton
                    to={strings.kakaoChatUrl}
                    variant="outlined"
                    external
                    fullWidth
                    size="small"
                  >
                    {strings.contactWithKakaoTalk}
                  </LinkButton>
                </>
                }
              </CardContent>
            </Card>
            <Spacer/>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={handlePurchase}
                disabled={props.checkingOut}
              >
                신청하기
              </Button>
            </div>
          </div>
          }
          <Spacer/>
          <Divider/>
          <Spacer/>
          <Typography variant="subtitle2">
            환불규정
          </Typography>
          <Typography variant="caption">
            - 결제이후 ~ 1회차 모임의 1일전: 결제금액 전액환불<br/>
            - 1회차 모임 당일 ~ 2회차 모임의 1일전: 결제금액의 80% 환불<br/>
            - 2회차 모임 당일 ~ 3회차 모임의 1일전: 결제금액의 60% 환불<br/>
            - 3회차 모임 당일 ~ 4회차 모임의 1일전: 결제금액의 40% 환불<br/>
            - 4회차 모임 당일 이후: 환불불가
          </Typography>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  data: state.payment.data,
  billingData: state.payment.billingData,
  billingResponse: state.payment.billingResponse,
  billingError: state.payment.billingError,
  checkingOut: state.payment.checkingOut,
  detail: state.club.detail,
  dialogOpen: state.payment.dialogOpen,
  signedIn: state.auth.signedIn,
  jwtPayload: state.auth.jwtPayload,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readClubDetail, setPaymentData, startCheckout,
    setBillingData, resetBillingData, createBillingTransaction },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDialog) as any;
