import md5 from 'md5';
import { startCheckout, finishCheckout, PaymentData, PaymentCustomData } from '../state/payment';
import { store } from '../App';
import { Club } from '../state/club';

// ref: https://github.com/iamport/iamport-manual/tree/master/인증결제#214-callback의-구성
export interface IamportPaymentResponse {
  success: boolean;
  error_code?: string;
  error_msg?: string;
  imp_uid: string;
  merchant_uid: string;
  pay_method: 'card' | 'trans' | 'vbank' | 'phone';
  paid_amount: string;
  status: 'ready' | 'paid' | 'cancelled' | 'failed';
  name: string;
  pg_tid: string;
  custom_data: PaymentCustomData;
  paid_at: number; // UNIX timestamp
  receipt_url: string;
}

export interface IamportPaymentPayload extends PaymentData {
  pg: 'html5_inicis';
  pay_method: 'card' | 'trans' | 'vbank' | 'phone';
}

export const generateMerchantUid = () => {
  const randomSeed = new Date().toString();
  const hashedString = md5(randomSeed);
  return `merchant_${hashedString.slice(-11)}`;
};

export const generateInitialPaymentData = (club: Club): PaymentData => {
  return {
    merchant_uid: generateMerchantUid(),
    amount: calculatePaymentAmount(club),
    name: club.name,
    buyer_name: '',
    custom_data: {
      club_id: club.id,
      user_id: undefined,
    },
    m_redirect_url: `${document.location.protocol}//${document.location.host}/payment/complete/`,
  };
};

export const calculatePaymentAmount = (club: Club) => {
  if (club.member_count < club.minimum_capacity) {
    return club.discounted_price;
  }
  return club.full_price;
};

const responseHandler = (response: IamportPaymentResponse, payload: IamportPaymentPayload) => {
  if (response.success) {
    document.location.href = `${payload.m_redirect_url}?imp_uid=${response.imp_uid}`;
  } else {
    alert(`결제를 실패했습니다.\n${response.error_msg}`);
    store.dispatch(finishCheckout());
  }
};

export const requestPay = (data: PaymentData) => {
  const payload: IamportPaymentPayload = {
    pg: 'html5_inicis',
    pay_method: 'card',
    ...data,
  };
  store.dispatch(startCheckout());
  IMP.request_pay(
    payload,
    (response: IamportPaymentResponse) => responseHandler(response, payload),
  );
};
