import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import LinkButton from '../base/LinkButton';
import Progress from '../base/Progress';
import MembershipItem from '../membership/MembershipItem';

import { createMembership, MembershipState } from '../../state/membership';
import { AppState } from '../../state/rootReducer';
import * as strings from '../../assets/values/strings';

const Wrapper = styled.div`
  margin: 60px 16px;
`;

const Spacer = styled.div`
  height: 16px;
`;

interface PaymentCompleteProps {
  createMembership: typeof createMembership;
}

class PaymentComplete extends React.Component<PaymentCompleteProps & MembershipState> {

  getImpUid = () => {
    const name = 'imp_uid';
    const parsed = queryString.parse(document.location.search);
    return parsed[name];
  }

  componentDidMount() {
    const impUid = this.getImpUid();
    if (!impUid) return;

    const data = {
      imp_uid: impUid,
    };
    this.props.createMembership(data);
  }

  render() {
    if (!this.props.detail) {
      return <Progress/>;
    }

    ReactGA.event({
      category: strings.GoogleAnalyticsEvent.category.commerce,
      action: strings.GoogleAnalyticsEvent.action.purchase,
      label: strings.GoogleAnalyticsEvent.label.membership,
    });

    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id: this.getImpUid(),
      revenue: this.props.detail.transactions[0].amount,
    });

    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id: this.getImpUid(),
      name: this.props.detail.info.name,
      sku: this.props.detail.info.id,
      price: this.props.detail.transactions[0].amount,
    });

    ReactGA.plugin.execute('ecommerce', 'send', {});

    fbq('tack', 'Purchase');

    return (
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} lg={4}>
          <Wrapper>
            <Helmet
              title="결제 완료 | 향유고래"
            />
            <Typography align="center" color="primary">
              <DoneIcon style={{ fontSize: 100 }}/>
            </Typography>
            <Typography gutterBottom align="center" variant="h6">
              모임 신청이 완료되었습니다.
            </Typography>
            <Spacer/>
            <MembershipItem
              membership={this.props.detail}
            />
            <Spacer/>
            <LinkButton to="/clubs" size="medium" variant="outlined">
              모임 더 둘러보기
            </LinkButton>
          </Wrapper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  detail: state.membership.detail,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { createMembership },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentComplete);
