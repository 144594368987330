import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { closeConfirmDialog, UtilityState } from '../../state/utility';
import { AppState } from '../../state/rootReducer';

interface ConfirmDialogProps extends UtilityState {
  closeConfirmDialog: typeof closeConfirmDialog;
}

const ConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = (props) => {

  const handleConfirm = () => {
    props.onConfirm();
    props.closeConfirmDialog();
  };

  return (
    <Dialog
      open={props.confirmDialogOpen}
      onClose={props.closeConfirmDialog}
    >
      <DialogTitle>{props.confirmTitle}</DialogTitle>
      <DialogActions>
        <Button onClick={props.closeConfirmDialog} color="primary">
          취소
        </Button>
        <Button onClick={handleConfirm} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const mapStateToProps = (state: AppState) => ({
  confirmTitle: state.utility.confirmTitle,
  confirmDialogOpen: state.utility.confirmDialogOpen,
  onConfirm: state.utility.onConfirm,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { closeConfirmDialog },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog) as any;
