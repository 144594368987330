import api, { ApiPromiseAction, ApiAction } from './../api';
import { fulfilled, pending } from './helper';

const READ_REVIEWS = 'review/readReviews';
const CREATE_REVIEW = 'review/createReview';

export function readReviews(cursor: string | null = null, pageSize: number = 5): ApiPromiseAction {
  const request = api.get('/reviews/', { cursor, page_size: pageSize });
  return {
    type: READ_REVIEWS,
    payload: request,
    meta: { cursor },
  };
}

export function createReview(data: object): ApiPromiseAction {
  const request = api.post('/reviews/', data);
  return {
    type: CREATE_REVIEW,
    payload: request,
  };
}

export interface Review {
  id: number;
  user: number;
  user_nickname: string;
  club: number;
  club_name: string;
  club_image: string;
  title: string;
  body: string;
  created_at: string;
}

export interface ReviewFormData {
  user: number;
  club: number;
  title: string;
  body: string;
}

export interface ReviewState {
  reviews: Review[];
  next: string | null;
  currentCursor: string | null;
  loadingReviews: boolean;
}

const INITIAL_REVIEW_STATE: ReviewState = {
  reviews: [],
  next: null,
  currentCursor: null,
  loadingReviews: true,
};

export default function (
  state: ReviewState = INITIAL_REVIEW_STATE,
  action: ApiAction,
): ReviewState {
  switch (action.type) {
    case pending(READ_REVIEWS): {
      return {
        ...state,
        loadingReviews: true,
      };
    }
    case fulfilled(READ_REVIEWS): {
      let reviews;
      if (action.meta.cursor === null) {
        reviews = action.payload.data.results;
      } else {
        reviews = state.reviews.concat(action.payload.data.results);
      }
      return {
        ...state,
        reviews,
        next: action.payload.data.next,
        currentCursor: action.meta.cursor,
        loadingReviews: false,
      };
    }
    case fulfilled(CREATE_REVIEW): {
      const newReview = action.payload.data as Review;
      const reviews = [newReview].concat(state.reviews);
      return {
        ...state,
        reviews,
      };
    }
    default: {
      return state;
    }
  }
}
