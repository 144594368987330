import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Progress from '../base/Progress';

import { readQuestions, UtilityState } from '../../state/utility';
import { AppState } from '../../state/rootReducer';

const Wrapper = styled.div`
  max-width: 700px;
  margin: 60px auto;
  padding: 0 16px;
  line-height: 1.5;
`;

const PanelContainer = styled.div`
  margin-top: 24px;
`;

interface FAQProps {
  readQuestions: typeof readQuestions;
}

interface FAQState {
  expanded: number | null;
}

class BaseFAQ extends React.Component<FAQProps & UtilityState> {
  state: FAQState = {
    expanded: null,
  };

  componentDidMount() {
    this.props.readQuestions();
  }

  handleChange = (panel: number) => (event: any, expanded: boolean) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  }

  render() {
    if (!this.props.questions || this.props.questions.length === 0) {
      return <Progress/>;
    }

    return (
      <PanelContainer>
      {this.props.questions.map(question => (
        <ExpansionPanel
          key={question.id}
          expanded={this.state.expanded === question.id}
          onChange={this.handleChange(question.id)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">{question.title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="body1">
              {question.body}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
      </PanelContainer>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  questions: state.utility.questions,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  { readQuestions },
  dispatch,
);

const FAQ = connect(mapStateToProps, mapDispatchToProps)(BaseFAQ) as any;

export { FAQ };
export default () => (
  <Wrapper>
    <Helmet
      title="도움말 | 향유고래"
    />
    <Typography variant="h5">도움말</Typography>
    <FAQ/>
  </Wrapper>
);
