import React from 'react';

import NavigationChevronRight from '@material-ui/icons/ChevronRight';
import NavigationChevronLeft from '@material-ui/icons/ChevronLeft';
import NavigationFirstPage from '@material-ui/icons/FirstPage';
import NavigationLastPage from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

interface PageNumberPaginatorProps {
  count: number;
  currentPage: number;
  onPageClick(page: number): void;
}

const PageNumberPaginator: React.FunctionComponent<PageNumberPaginatorProps> = (props) => {
  const lastPage = Math.ceil(props.count / 10) | 1;
  return (
    <div style={{ textAlign: 'center', padding: 8 }}>
      <IconButton
        onClick={() => props.onPageClick(1)}
      >
        <NavigationFirstPage/>
      </IconButton>
      <IconButton
        disabled={props.currentPage === 1}
        onClick={() => props.onPageClick(props.currentPage - 1)}
      >
        <NavigationChevronLeft/>
      </IconButton>
      <Typography variant="subtitle1" style={{ display: 'inline' }}>
        {props.currentPage} / {lastPage}
      </Typography>
      <IconButton
        disabled={props.currentPage >= lastPage}
        onClick={() => props.onPageClick(props.currentPage + 1)}
      >
        <NavigationChevronRight/>
      </IconButton>
      <IconButton
        onClick={() => props.onPageClick(lastPage)}
      >
        <NavigationLastPage/>
      </IconButton>
    </div>
  );
};

export default PageNumberPaginator;
