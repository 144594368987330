import { AnyAction } from 'redux';
import api from '../api';
import { fulfilled, pending } from './helper';

const SET_PAYMENT_DATA = 'payment/setPaymentData';
const RESET_PAYMENT_DATA = 'payment/resetPaymentData';
const START_CHECKOUT = 'payment/startCheckout';
const FINISH_CHECKOUT = 'payment/finishCheckout';
const SET_BILLING_DATA = 'payment/setBillingData';
const RESET_BILLING_DATA = 'payment/resetBillingData';
const CREATE_BILLING_TRANSACTION = 'payment/createBillingTransaction';

export interface PaymentCustomData {
  club_id: number;
  user_id: number | undefined;
}

export interface PaymentData {
  merchant_uid: string; // (필수) `merchant_${md5((new Date()).toString()).slice(-11)}`
  amount: number;  // (필수)
  name: string; // (필수)
  buyer_name: string;
  buyer_email?: string;
  buyer_tel?: string;
  custom_data: PaymentCustomData;
  m_redirect_url: string; // 결제 완료 혹은 실패 후 리다이렉트할 URL (데스크톱은 내부 callback / 모바일은 PG 모듈이 처리)
}

export interface BillingData {
  merchant_uid: string;
  amount: number;
  name: string;
  buyer_name: string;
  buyer_email?: string;
  buyer_tel?: string;
  custom_data: PaymentCustomData;
  card_number: string;  // 카드번호(dddd-dddd-dddd-dddd)
  expiry: string;  // 카드 유효기간(YYYY-MM)
  birth: string;  // 생년월일6자리(법인카드의 경우 사업자등록번호10자리)
  pwd_2digit: string;  // 비밀번호 앞 두자리
  card_quota?: number;  // 5만원 이상을 결제하는 경우, 할부 개월 수. 2이상
}

export interface BillingResponse {
  merchant_uid: string;
  imp_uid: string;
  pg_tid: string;
  receipt_url: string;
  amount: number;
}

export interface Transaction {
  id: number;
  amount: number;
  receipt_url: string;
  is_cancelled: boolean;
  created_at: string;
}

export function setPaymentData(data: PaymentData): AnyAction {
  return {
    type: SET_PAYMENT_DATA,
    payload: data,
  };
}

export function resetPaymentData(): AnyAction {
  return {
    type: RESET_PAYMENT_DATA,
  };
}

export function setBillingData(data: BillingData): AnyAction {
  return {
    type: SET_BILLING_DATA,
    payload: data,
  };
}

export function resetBillingData(): AnyAction {
  return {
    type: RESET_BILLING_DATA,
  };
}

export function createBillingTransaction(data: BillingData): AnyAction {
  const request = api.post('/transactions/pay/', data);
  return {
    type: CREATE_BILLING_TRANSACTION,
    payload: request,
  };
}

export function startCheckout(): AnyAction {
  return {
    type: START_CHECKOUT,
  };
}

export function finishCheckout(): AnyAction {
  return {
    type: FINISH_CHECKOUT,
  };
}

export interface PaymentState {
  data: PaymentData | undefined;
  dialogOpen: boolean;
  checkingOut: boolean;
  billingData: BillingData | undefined;
  billingResponse: BillingResponse | undefined;
  billingError: string | undefined;
}

const INITIAL_PAYMENT_STATE: PaymentState = {
  data: undefined,
  dialogOpen: false,
  checkingOut: false,
  billingData: undefined,
  billingResponse: undefined,
  billingError: undefined,
};

export default function (
  state: PaymentState = INITIAL_PAYMENT_STATE,
  action: AnyAction,
): PaymentState {
  switch (action.type) {
    case RESET_PAYMENT_DATA: {
      return {
        ...state,
        data: undefined,
        dialogOpen: false,
      };
    }
    case SET_PAYMENT_DATA: {
      return {
        ...state,
        data: action.payload,
        dialogOpen: true,
      };
    }
    case SET_BILLING_DATA: {
      return {
        ...state,
        billingData: action.payload,
      };
    }
    case RESET_BILLING_DATA: {
      return {
        ...state,
        billingData: undefined,
      };
    }
    case pending(CREATE_BILLING_TRANSACTION): {
      return {
        ...state,
        billingResponse: undefined,
        billingError: undefined,
      };
    }
    case fulfilled(CREATE_BILLING_TRANSACTION): {
      if (!action.payload.ok) {
        return {
          ...state,
          billingResponse: undefined,
          billingError: JSON.stringify(action.payload.data),
        };
      }
      return {
        ...state,
        billingResponse: action.payload,
        billingError: undefined,
      };
    }
    case START_CHECKOUT: {
      return {
        ...state,
        checkingOut: true,
      };
    }
    case FINISH_CHECKOUT: {
      return {
        ...state,
        checkingOut: false,
      };
    }
    default: {
      return state;
    }
  }
}
